import React from "react";
import "./CommonBanner.scss";
const CommonBanner = ({ CarLogo, className, children }) => {
  return (
    <div className="common-banner">
      <div className="common-container">
        <div className="common-container-inner d-flex">{children}</div>
      </div>
    </div>
  );
};

export default CommonBanner;
