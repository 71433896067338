import React, { useState } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Field, reduxForm } from "redux-form";
import { changePassword } from "../../../Services/changePassword";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import LoaderWrapper from "../../Common/Loader/Loader";
import Popup from "../../Common/Popup/Popup";
import "./../../Auth/Auth.scss";
import { required, validate } from "./../DealerSignup/validate";

function ChangePassword(props) {
  const { handleSubmit, pristine, reset, submitting } = props;
  const [message, setMessage] = useState("");
  const [field, setField] = useState({
    szEmail: "",
    szPassword: "",
  });
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  // @api call
  const handleChangePassword = (values) => {
    setLoader(true);
    if (validate(values)) {
      changePassword(props.formValues)
        .then((res) => {
          if (res.success === false) {
            setLoader(false);
          } else {
            setPopup(true);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const handleSuccess = () => {
    setPopup(false);
  };

  return (
    <>
      {loader && <LoaderWrapper />}
      <div className="auth-container">
        <form
          onSubmit={handleSubmit(handleChangePassword)}
          className="auth-form-wrapper logincls mt-0"
        >
          <h1 className="heading text-left">Change your password</h1>
          <div className="form-field">
           
            <Field
              name="szOldPassword"
              placeholder="Old Password *"
              type="password"
              component={Input}
              className={`width-100`}
              validate={[required]}
            />
            {/* {props.szEmail === undefined && (
              <span className="error">{field.szEmail}</span>
            )} */}
          </div>
          <div className="form-field">
          
            <Field
              name="szNewPassword"
              placeholder="New Password *"
              type="password"
              component={Input}
              className={`width-100`}
              validate={[required]}
            />
            {/* {props.szPassword === undefined && (
              <span className="error">{field.szPassword}</span>
            )} */}
          </div>
          <div className="form-field">
           
            <Field
              name="szConfirmPassword"
              placeholder="Confirm Password *"
              type="password"
              component={Input}
              className={`width-100`}
              validate={[required]}
            />
            {/* {props.szPassword === undefined && (
              <span className="error">{field.szPassword}</span>
            )} */}
          </div>
          <Button className="primary width-100" type="submit">
            Change Password
          </Button>

          {/* message popup */}
        </form>
        <Popup
          onClick={handleSuccess}
          state={popup}
          heading="Change Password"
          btnText="Filter"
        >
          <h3>Password has been change successfully.</h3>
          <button className="btn primary" onClick={handleSuccess}>
            Ok
          </button>
        </Popup>
      </div>
    </>
  );
}

ChangePassword = reduxForm({
  form: "changePassword",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(ChangePassword);

export default connect((state) => {
  const formValues = state?.form["changePassword"]?.values;
  return {
    formValues,
    state,
  };
})(ChangePassword);
