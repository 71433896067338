const febricOption = [
  {
    value: "",
    label: "Select fabric...",
    disabled: true,
  },
  {
    value: "leather",
    label: "Leather",
  },
  {
    value: "half leather",
    label: "Half leather",
  },
  {
    value: "cloth",
    label: "Cloth",
  },
  {
    value: "suede",
    label: "Suede",
  },

  {
    value: "half suede",
    label: "Half Suede",
  },
];

const colorOption = [
  {
    value: "",
    label: "Select Color...",
    disabled: true,
  },
  {
    value: "white",
    label: "White",
  },
  {
    value: "cream",
    label: "Cream",
  },
  {
    value: "tan",
    label: "Tan",
  },
  {
    value: "brown",
    label: "Brown",
  },

  {
    value: "blackblue",
    label: "Black & blue",
  },
  {
    value: "Blackred",
    label: "Black & red",
  },
  {
    value: "Blackwhite",
    label: "Black & white",
  },
  {
    value: "Blackgrey",
    label: "Black & grey",
  },
];

const noOfVehicleOption = [
  {
    value: "",
    label: "Select number of vehicle...",
    disabled: true,
  },
  {
    value: "0-10",
    label: "0-10",
  },
  {
    value: "10-25",
    label: "10-25",
  },
  {
    value: "25-50",
    label: "25-50",
  },
  {
    value: "50-100",
    label: "50-100",
  },

  {
    value: "100-250",
    label: "100-250",
  },
  {
    value: "250-500",
    label: "250-500",
  },
  {
    value: "500+",
    label: "500+",
  },
];

const positionOption = [
  {
    value: "",
    label: "Select position...",
    disabled: true,
  },
  {
    value: "Buyer",
    label: "Buyer",
  },
  {
    value: "CEO / Managing Director",
    label: "CEO / Managing Director",
  },
  {
    value: "Director",
    label: "Director",
  },
  {
    value: "Group Buyer",
    label: "Group Buyer",
  },

  {
    value: "Group Sales Manager",
    label: "Group Sales Manager",
  },
  {
    value: "Owner / Proprietor",
    label: "Owner / Proprietor",
  },
  {
    value: "Sales Manager",
    label: "Sales Manager",
  },
  {
    value: "Stock Controller",
    label: "Stock Controller",
  },
];

const distanceOption = [
  {
    value: "",
    label: "Select distance...",
    disabled: true,
  },
  {
    value: "0 miles (drop-off only)",
    label: "0 miles (drop-off only)",
  },
  {
    value: "Up to 10 miles",
    label: "Up to 10 miles",
  },
  {
    value: "Up to 20 miles",
    label: "Up to 20 miles",
  },
  {
    value: "Up to 30 miles",
    label: "Up to 30 miles",
  },

  {
    value: "Up to 50 mile",
    label: "Up to 50 mile",
  },
  {
    value: "Up to 75 miles",
    label: "Up to 75 miles",
  },
  {
    value: "Up to 100 miles",
    label: "Up to 100 miles",
  },
  {
    value: "Up to 200 miles",
    label: "Up to 200 miles",
  },
  {
    value: "Up to 500 miles",
    label: "Up to 500 miles",
  },
  {
    value: "Nationwide",
    label: "Nationwide",
  },
];

const contactUsOption = [
  {
    value: "Discuss something",
    label: "Discuss something",
  },
  {
    value: "Ask question",
    label: "Ask Question",
  },
];

export {
  febricOption,
  colorOption,
  noOfVehicleOption,
  positionOption,
  distanceOption,
  contactUsOption,
};
