import React from "react";
import { ReactComponent as CloseBlack } from "../../../Assets/Icons/close.svg";
import "./Popup.scss";
const Popup = (props) => {
  return (
    <div className={`custom-popup ${props.state ? "show" : ""}`}>
      <div className={`popup ${props.additionalClass}`}>
        <div className="header-popup">
          <h5 className="heading mb-0">{props.heading}</h5>
          <CloseBlack className="times" onClick={props.onClick} />
        </div>
        <div className="popup-body">{props.children}</div>
        {/* <div className="footer-popup">
          <Button className="primary" onClick={props.onClick}>
            {props.btnText}
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default Popup;
