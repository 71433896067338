import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../Components/Common/Button';
import CommonBanner from '../../Components/Common/CommonBanner/CommonBanner';
import LoaderWrapper from '../../Components/Common/Loader/Loader';
import Popup from '../../Components/Common/Popup/Popup';
import Path from '../../Constants/Routes';
import ImageCard from '../VehicleDetail/ImageCard';
import DummyCarImg from './../../Assets/Icons/carlist.svg';
import { listAllVehicle } from '../../Services/vehicleList';
import CurrencyFormat from 'react-currency-format';
import './CarListing.scss';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import leftIcon from './../../Assets/Icons/arrow-right.png';
import { ReactComponent as DistanceBlack } from './../../Assets/Icons/direction.svg';
import { s3URL } from '../../Constants/constants';
import { insertSpace } from '../../Helpers/Helpers';
import smoothscroll from 'smoothscroll-polyfill';

const CarGrid = (props) => {
    const [vehicleDetail, setVehicle] = useState(null);
    const [image, setImage] = useState('');
    const [popup, setPopup] = useState(false);

    useEffect(() => {
        setVehicle(props.vehicle);
    }, [props.vehicle]);

    // scroll and current image
    const setCurrentImage = (image) => {
        setImage(image);
    };

    const scrollNext = (id) => {
        document.getElementById(id + 'slider-scroll').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
        smoothscroll.polyfill();
    };

    const scrollPrev = (id) => {
        document.getElementById(id + 'slider-scroll').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
        smoothscroll.polyfill();
    };

    const vehImgs = [
        vehicleDetail?.szExteriorFrontDriverSide,
        vehicleDetail?.szExteriorBackDriverSide,
        vehicleDetail?.szExteriorFrontPassengerSide,
        vehicleDetail?.szExteriorBackPassengerSide,
        vehicleDetail?.szInteriorFrontSeats,
        vehicleDetail?.szInteriorRearSeats,
        vehicleDetail?.szInteriorDashboard,
        vehicleDetail?.szInteriorBoot,
        vehicleDetail?.szExteriorFrontSeats,
        vehicleDetail?.szExteriorRearSeats,
        vehicleDetail?.szExteriorDashboard,
        vehicleDetail?.szInteriorFrontDriverSide,
        vehicleDetail?.szInteriorBackDriverSide,
        vehicleDetail?.szInteriorFrontPassengerSide,
        vehicleDetail?.szInteriorBackPassengerSide
    ].filter((i) => !!i);

    const handleThankuPopup = () => {
        setPopup(false);
    };
    return (
        <>
            {vehicleDetail ? (
                <>
                    <div className="list-card">
                        <div className="avatar">
                            <Link to={`${Path.vehicleDetail}${vehicleDetail.id}`} key={vehicleDetail.id}>
                                {vehImgs.length > 0 ? (
                                    <ImageCard avtar={image === '' ? vehImgs[0] : image} selfBg={true} />
                                ) : (
                                    <div className="image-card">
                                        <img className="avtar" src={DummyCarImg} alt="No Photo actual Uploaded." />
                                    </div>
                                )}
                            </Link>
                            <div className="carousel-wrapper-class">
                                <div className="other-images d-flex" style={{ height: 76 }} id={vehicleDetail.id + 'slider-scroll'}>
                                    {vehImgs.length ? (
                                        vehImgs.map((im) => (
                                            <span className="other-image">
                                                <img className={im === image ? 'active' : ''} onClick={() => setCurrentImage(im)} src={s3URL + im} />
                                            </span>
                                        ))
                                    ) : (
                                        <label className="no-photo">Photos not found</label>
                                    )}
                                    <div className={`arrow ${vehImgs.length ? '' : 'disable-next-prev'}`}>
                                        <span className="arrow-left" onClick={() => scrollPrev(vehicleDetail?.id)}>
                                            <img src={leftIcon} alt="Prev" />
                                        </span>
                                        <span className="arrow-right" onClick={() => scrollNext(vehicleDetail?.id)}>
                                            <img src={leftIcon} alt="Next" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <ul className="tab d-flex list-style-none">
                                <li className="title">{insertSpace(vehicleDetail?.szVehicleNumber)}</li>
                                {/* <li className="location">
                                    <DistanceBlack className="icon" />
                                    <span>{vehicleDetail?.szMiles} mi away</span>
                                </li> */}
                            </ul>
                            <h2 className="card-heading">{vehicleDetail?.szVehicleDetails?.make || 'N/A'}</h2>
                            <h2 className="card-sub-heading" title={vehicleDetail?.szVehicleDetails?.model}>
                                {vehicleDetail?.szVehicleDetails?.model || 'N/A'}
                            </h2>
                            <ul className="feature width-100 list-style-none">
                                <li>{vehicleDetail?.szVehicleDetails?.yearOfManufacture || 'N/A'}</li>
                                <li>
                                    {vehicleDetail?.szMileage ? (
                                        <CurrencyFormat value={vehicleDetail?.szMileage} displayType={'text'} thousandSeparator={true} />
                                    ) : (
                                        'N/A'
                                    )}
                                </li>
                                <li>{vehicleDetail?.szVehicleDetails?.fuelType || 'N/A'}</li>
                                <li>{vehicleDetail?.szVehicleDetails?.colour || 'N/A'} </li>
                            </ul>
                            <div className="all-price">
                                <div className="heading-price">
                                    <span className="cap">Start Price:</span>
                                    <span className="price">
                                        <CurrencyFormat value={vehicleDetail?.dMinimumPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                    </span>
                                </div>
                                <div className="heading-price">
                                    {vehicleDetail?.TradeClean ? (
                                        <>
                                            <span className="cap">CAP Clean:</span>
                                            <span className="price">
                                                <CurrencyFormat value={vehicleDetail?.TradeClean} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="cap">CAP Clean:</span>
                                            <span className="price">N/A</span>
                                        </>
                                    )}
                                </div>

                                {/* <div className="heading-price">
                                    {vehicleDetail?.dMinimumPrice ? (
                                        <>
                                            <span className="cap">Min Bid:</span>
                                            <span className="price">
                                                <CurrencyFormat
                                                    value={vehicleDetail?.dMinimumPrice}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'£'}
                                                />
                                            </span>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div> */}
                                <div className="heading-price">
                                    {vehicleDetail?.seller_details?.szPostCode ? (
                                        <>
                                            <span className="cap">Location:</span>
                                            <span
                                                className="price"
                                                onClick={() => {
                                                    setPopup(true);
                                                }}
                                            >
                                                {vehicleDetail?.seller_details?.szPostCode}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="cap">Location:</span>
                                            <span className="price">N/A</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
            <Popup onClick={handleThankuPopup} state={popup} heading="Map" btnText="Filter" additionalClass="map_CarGrid">
                <iframe
                    style={{ top: 0, border: '0px', height: 'calc(100vh - 230px)', width: '100%' }}
                    allowfullscreen
                    src={`https://maps.google.com/maps?q=${vehicleDetail?.seller_details?.szPostCode}&output=embed`}
                ></iframe>
            </Popup>
        </>
    );
};

export default CarGrid;
