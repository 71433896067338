import React from 'react';
import Stars from '../../Assets/Icons/Stars.png';
import Stars1 from '../../Assets/Icons/Stars1.png';
import Divider from '../../Assets/Icons/Divider.png';
import './CustomerReview.scss';

const CustomerReview = () => {
    return (
        <div className="customer-review">
            <div className="container-customer-review custom-scrollbar">
                <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
                    <h1 className="car-name">Customer Review</h1>
                </div>
                <div className="customer-review-lists">
                    <ul className="review-items">
                        <li className="review-item">
                            <img className="company-team" src={Stars} alt="companies team" />
                            <h3>Hassle free sale!</h3>
                            <div className="review-content">
                                <p>
                                    Vivamus in tortor id metus porta scelerisq a a quam. Sed bibendum fermentum imperdiet. Vivamus massa justo, lacinia eu
                                    egestas a, dignissim at eros. Aliquam auctor at felis eget commodo. Donec lacus, mattis nec lacus sed, ultricies gravid.
                                </p>
                            </div>
                            <div className="customer-name">
                                <p>
                                    <strong>Customer 1</strong>
                                </p>
                            </div>
                            <span className="divider">
                                <img className="company-team" src={Divider} alt="companies team" />
                            </span>
                        </li>
                        <li className="review-item">
                            <img className="stars" src={Stars1} alt="companies team" />
                            <h3>Simple process</h3>
                            <div className="review-content">
                                <p>
                                    Ut vel faucibus odio, sit amet maximus quam. Aenean non neque eros. Pellentesque habitant morbi tristique senectus et netus
                                    et malesuada fames ac turpis egestas.
                                </p>
                            </div>
                            <div className="customer-name">
                                <p>
                                    <strong>Customer 2</strong>
                                </p>
                            </div>
                            <span className="divider">
                                <img className="company-team" src={Divider} alt="companies team" />
                            </span>
                        </li>
                    </ul>
                    <ul className="review-items">
                        <li className="review-item">
                            <img className="company-team" src={Stars} alt="companies team" />
                            <h3>Great experience</h3>
                            <div className="review-content">
                                <p>
                                    Suspendisse sit amet magna in libero cursus tincidunt. Maecenas non consectetur metus. Donec tincidunt dui nec quam
                                    vestibulum, nec accumsan leo vestibulum.
                                </p>
                            </div>
                            <div className="customer-name">
                                <p>
                                    <strong>Customer 3</strong>
                                </p>
                            </div>
                            <span className="divider">
                                <img className="company-team" src={Divider} alt="companies team" />
                            </span>
                        </li>
                        <li className="review-item">
                            <img className="stars" src={Stars1} alt="companies team" />
                            <h3>Quick and simple</h3>
                            <div className="review-content">
                                <p>
                                    Donec nec nibh ullamcorper, maximus leo quis, hendrerit quam. In fringilla malesuada nunc, a malesuada mi fermentum eu.
                                    Integer pellentesque dui vitae neque eleifend, a suscipit est congue. Vestibulum elit velit, placerat eu massa vel.
                                </p>
                            </div>
                            <div className="customer-name">
                                <p>
                                    <strong>Customer 4</strong>
                                </p>
                            </div>
                            <span className="divider">
                                <img className="company-team" src={Divider} alt="companies team" />
                            </span>
                        </li>
                    </ul>
                    <ul className="review-items">
                        <li className="review-item">
                            <img className="company-team" src={Stars} alt="companies team" />
                            <h3>Helpful Let The CarGo team</h3>
                            <div className="review-content">
                                <p>
                                    Sed lectus urna, commodo non imperdiet at placerat at mauris. Sed commodo lacinia nulla eu scelerisque. Pellentesque id
                                    lectus eu ipsum elementum porttitor eu nec purus. Fusce ullamcorper sagittis justo sit amet.
                                </p>
                            </div>
                            <div className="customer-name">
                                <p>
                                    <strong>Customer 5</strong>
                                </p>
                            </div>
                            <span className="divider">
                                <img className="company-team" src={Divider} alt="companies team" />
                            </span>
                        </li>
                        <li className="review-item">
                            <img className="stars" src={Stars1} alt="companies team" />
                            <h3>Excellent service</h3>
                            <div className="review-content">
                                <p>
                                    Fusce fermentum viverra arcu nec lacinia. Vestibulum placerat, velit non dapibus porttitor, dui velit efficitur quam, eget
                                    egestas est ex in ipsum.
                                </p>
                            </div>
                            <div className="customer-name">
                                <p>
                                    <strong>Customer 6</strong>
                                </p>
                            </div>
                            <span className="divider">
                                <img className="company-team" src={Divider} alt="companies team" />
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CustomerReview;
