const Path = {
  home: "/",
  login: "/login",
  signup: "/signup",
  aboutUs: "/about",
  cookiePolicy: "/cookies",
  team: "/team",
  faq: "/faq",
  terms: "/terms",
  pricing: "/pricing",
  privacyPolicy: "/privacy-policies",
  termsConditions: "/terms",
  dealerProcess: "/dealer-process",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  changePassword: "/customer-profile/change-password",
  contactUs: "/contact-us",
  customerReview: "/customer-review",
  userSetting: "/user-setting",
  more: "/more",
  carDetail: "/car-detail",
  vehicleDetail: "/vehicle-detail/",
  carListing: "/car-listing",
  customerProfile: "/customer-profile",
  bidList: "/customer-profile/",
  customerMessage: "/customer-profile/customer-message",
  editProfile: "/customer-profile/edit-profile",
  bidsWon: "/customer-profile/bids-won",
  bidsLost: "/customer-profile/bids-lost",
  bidsCompleted: "/customer-profile/bids-completed",
  viewBids: "/customer-profile/view-bids/",
  // select
};
export default Path;
