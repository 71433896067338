import * as moment from "moment";
import React, { useEffect, useState } from "react";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as CommentIcon } from "../../Assets/Icons/comment.svg";
import { s3URL } from "../../Constants/constants";
import Path from "../../Constants/Routes";
import { getBidList } from "./../../Services/listBids";
import DummyCarImg from "./../../Assets/Icons/carlist.svg";
import ReactPaginate from "react-paginate";

import "./CustomerProfile.scss";
import CurrencyFormat from "react-currency-format";

const ViewBids = ({ data, name }) => {
  const [loader, setLoader] = useState(false);
  const [bidList, setBidList] = useState([]);
  let { slug } = useParams();
  

  const [count, setCount] = useState(null);
   
  useEffect(() => {
    let req = {
      "idVehicle":slug
    }
    getDealerBidList(req);
  }, []);

  // @api
  const getDealerBidList = (req) => {
    setLoader(true);
    console.log('tetetete',req);
    console.log('tetetetedd',data);
    getBidList(req)
      .then(async (res) => {
        if (res.status === false) {
          console.log("something went wrong");
        } else {
          await setBidList(res?.data?.data?.data);
          await setCount(res?.data?.data?.total)
          await setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handlePageClick = (data) => {
    let req = {
      "idVehicle":slug,
      "page":data.selected+1
    }
    getDealerBidList(req)
  }

  return (
    <>
    <div className="backbtn">
      <Link className="btn primary" to={Path.bidsWon}>
         Back
      </Link>
    </div>
    <ul> 
      {loader ? <LoaderWrapper /> : null}      
      {bidList?.map((bid, index) => {
        return (
          <li key={index}>
            <div className="item">
              <div className="accountVehicleCardView">
                <div className="vehiclesSection-lft">
                  <div className="photoArea">
                    <img
                      src={
                        bid?.szExteriorFrontDriverSide
                          ? s3URL + bid?.szExteriorFrontDriverSide
                          : DummyCarImg
                      }
                      alt="car logo"
                    />
                  </div>
                </div>
                <div className="vehiclesSection-rgt">
                  <div className="vrmArea">
                    <div className="vrmBadge-styles_badge">
                      <span className="text-uppercase">
                        {bid?.szVehicleNumber}
                      </span>
                    </div>
                  </div>
                  <div className="expirationArea"></div>
                  <div className="nameArea">
                    <span>{bid?.model}</span>
                  </div>
                  {/* <div className="bidArea">
                    <span className="mw-title-title6">
                      Status:&nbsp;
                    </span>
                    <span
                        className={`${
                          bid?.szStatus ? "text-green" : "text-danger"
                        }`}
                      >
                        {bid?.szStatus}
                      </span>
                      </div> */}
                      <div className="bid-ammount-date">
                      <div className="bidsamount">
                    <span className="mw-title-title6">
                    Bid Placed: 
                    </span>
                    <span>
                    <CurrencyFormat value={bid?.dBidAmount} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    </span>
                    </div>
                    <div className="bidsdate">
                    <span className="createdAt">                     
                      {moment(bid?.created_at).format("D/M/Y HH:mm")}
                    </span>
                  </div>
                  </div>
                  {/* <div className="viewdetail">
                  <Link
                      className="chatbtn btn primary"
                      to={{
                        pathname: Path.customerMessage,
                        state: {
                          vehicleID: bid?.idVehicle,
                          sellerID: bid?.idSeller,
                        },
                      }}
                    >
                      <span>
                        <CommentIcon/>
                      </span>
                    </Link>
                    <Link className="btn primary" to={Path.vehicleDetail + bid?.idVehicle}>
                     View details
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
    {!loader && bidList.length <1 && <h4 style={{ textAlign: "center" }}>No data found</h4>}
    {bidList.length <1 && count>10 && 
    <div className="pagination-wrapper">
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={count ? count / 10 : 0}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pull-right pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        // forcePage={pageNo}
      />
    </div>
    }
    </>
  );
};

export default ViewBids;
