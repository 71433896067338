import setting from "./settings";
export default (() => ({
  AUTH: `${setting.api.basicAuth}`,
  // Apis
  getVehicleDetail: `${setting.api.url}/vehicleDetails`,
  searchCar: `${setting.api.url}/vehicle`,
  sellerSignUp: `${setting.api.url}/sellerSignUp`,
  sellerLogin: `${setting.api.url}/sellerLogin`,
  sellerLoginOTP: `${setting.api.url}/verifyOtp`,
  dealerLogin: `${setting.api.url}/dealerLogin`,
  dealerSignup: `${setting.api.url}/dealerSignUp`,
  updateVehicleDetails: `${setting.api.url}/saveVehicleDetails`,
  vehicleList: `${setting.api.url}/vehicleList`,
  addBid: `${setting.api.url}/addBid`,
  getDealerBidList: `${setting.api.url}/getDealerBidList`,
  changePassword: `${setting.api.url}/changePassword`,
  messages: `${setting.api.url}/getChatList`,
  sendQueryReply: `${setting.api.url}/sendQueryReply`,
  getDealerProfile: `${setting.api.url}/getDealerDetails`,
  updateProfile: `${setting.api.url}/updateDealerDetails`,
  forgotPassword: `${setting.api.url}/forgotPassword`,
  getMessageDealer: `${setting.api.url}/getMessageDealer`,
  updateVehicleBidStatus: `${setting.api.url}/updateVehicleBidStatus`,
  // searchCar: ``
}))();
