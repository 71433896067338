import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Components/Common/Button";
import ComparePrices from "./../../Assets/Icons/compare-prices.png";
import FreeValuation from "./../../Assets/Icons/free-valuation.png";
import NoContactSelling from "./../../Assets/Icons/no-contact-selling.png";
import SellAnyCar from "./../../Assets/Icons/sale-any-car.png";
import Audi from "./../../Assets/Images/carlogo/audi.png";
import BMW from "./../../Assets/Images/carlogo/bmw.svg";
import Carlogo from "./../../Assets/Images/carlogo/carlogo.png";
import CarlogoTwo from "./../../Assets/Images/carlogo/carlogo_2.png";
import Ford from "./../../Assets/Images/carlogo/ford.png";
import Vauxhall from "./../../Assets/Images/carlogo/vauxhall.png";
import Company1 from "./../../Assets/Images/companies/company1.png";
import Company2 from "./../../Assets/Images/companies/company2.png";
import Company3 from "./../../Assets/Images/companies/company3.png";
import Company4 from "./../../Assets/Images/companies/company4.png";
import Company5 from "./../../Assets/Images/companies/company5.png";
import Company6 from "./../../Assets/Images/companies/company6.png";
import SellYourCar from "./../../Assets/Images/couple-buying.png";
import RatingStart from "./../../Assets/Images/rating-star.png";
import Review11 from "./../../Assets/Images/review1.png";
import Review12 from "./../../Assets/Images/review2.png";
import Review13 from "./../../Assets/Images/review3.png";
import WhySellFeaturedImage from "./../../Assets/Images/why-sell.png";
import { ReactComponent as CarIcon } from "../../Assets/Icons/car.svg";
import Path from "./../../Constants/Routes";
import "./Home.scss";

function Home(props) {
  let token = localStorage.getItem("token");
  return (
    <div className="cargo-home">
      <div className="banner-fold">
        <div className="container h-100 d-flex align-items-center">
          <div className="banner-content-wrapper">
            <h1 className="banner-heading">Online Auction Platform</h1>
            <p className="tagline">You Could Get up to £1,000* More</p>
            <div className="banner-form-group">
              {/* <InputCustom
                type="text"
                name="searchCar"
                className={`register-no ${
                  error !== "" ? "input-error shake-email" : ""
                }`}
                placeholder="Enter Reg"
                onChange={(e) => handleChange(e.target.value)}
              /> */}
              {!token && (
                <Link className="btn primary m-0" to={Path.signup}>
                  Sign up now
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* campareign info */}
      <div className="compare-compnies">
        <div className="head">
          <h2 className="heading">
            Compare <span>10+</span> Online Car Buyers and <span>1000+</span>
            Dealers
          </h2>
          <div className="company-logos">
            <img className="company-logo" src={Company1} alt="companies" />
            <img className="company-logo" src={Company2} alt="companies" />
            <img className="company-logo" src={Company3} alt="companies" />
            <img className="company-logo" src={Company4} alt="companies" />
            <img className="company-logo" src={Company5} alt="companies" />
            <img className="company-logo" src={Company6} alt="companies" />
          </div>
        </div>
        <div className="company-features">
          <div className="feature caricon">
            <CarIcon className="icon" />
            <h3 className="heading">Free Valuation</h3>
            <p className="text-description">
              Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Morbi
              bibendum quam at tincidunt.
            </p>
          </div>
          <div className="feature">
            <img className="icon" src={ComparePrices} alt="free valuation" />
            <h3 className="heading">Free Valuation</h3>
            <p className="text-description">
              Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Morbi
              bibendum quam at tincidunt.
            </p>
          </div>
          <div className="feature">
            <img className="icon" src={SellAnyCar} alt="free valuation" />
            <h3 className="heading">Free Valuation</h3>
            <p className="text-description">
              Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Morbi
              bibendum quam at tincidunt.
            </p>
          </div>
          <div className="feature">
            <img className="icon" src={NoContactSelling} alt="free valuation" />
            <h3 className="heading">Free Valuation</h3>
            <p className="text-description">
              Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Morbi
              bibendum quam at tincidunt.
            </p>
          </div>
        </div>
      </div>
      {/* why sell  car */}
      <div className="why-sell">
        <div className="container d-flex">
          <div className="featured-image d-flex align-items-end">
            <img
              className="featured-icon"
              src={WhySellFeaturedImage}
              alt="featured"
            />
          </div>
          <div className="content">
            <h2 className="heading">
              Why Sell Your
              <span className="d-block">Car the Car Go?</span>
            </h2>
            <ul className="list-style-none featured-point">
              <li className="item">
                <div className="point">
                  <h3 className="point-heading">Sell Any Car in 24 Hours</h3>
                  <p className="point-text">
                    Lorem ipsum dolor sit amet, consec tetur adipi scing elit.
                    Morbi bibendum quam.
                  </p>
                </div>
              </li>
              <li className="item">
                <div className="point">
                  <h3 className="point-heading">Sell Any Car in 24 Hours</h3>
                  <p className="point-text">
                    Lorem ipsum dolor sit amet, consec tetur adipi scing elit.
                    Morbi bibendum quam.
                  </p>
                </div>
              </li>
              <li className="item">
                <div className="point">
                  <h3 className="point-heading">Sell Any Car in 24 Hours</h3>
                  <p className="point-text">
                    Lorem ipsum dolor sit amet, consec tetur adipi scing elit.
                    Morbi bibendum quam.
                  </p>
                </div>
              </li>
              <li className="item">
                <div className="point">
                  <h3 className="point-heading">Sell Any Car in 24 Hours</h3>
                  <p className="point-text">
                    Lorem ipsum dolor sit amet, consec tetur adipi scing elit.
                    Morbi bibendum quam.
                  </p>
                </div>
              </li>
              <li className="item">
                <div className="point">
                  <h3 className="point-heading">Sell Any Car in 24 Hours</h3>
                  <p className="point-text">
                    Lorem ipsum dolor sit amet, consec tetur adipi scing elit.
                    Morbi bibendum quam.
                  </p>
                </div>
              </li>
              <li className="item">
                <div className="point">
                  <h3 className="point-heading">Sell Any Car in 24 Hours</h3>
                  <p className="point-text">
                    Lorem ipsum dolor sit amet, consec tetur adipi scing elit.
                    Morbi bibendum quam.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* sell any car */}
      <div className="sell-any-car">
        <div className="container">
          <h2 className="heading text-center">Sell Any Car Fast</h2>
          <ul className="car list-style-none">
            <li className="item">
              <img src={Audi} alt="Audi" />
            </li>
            <li className="item">
              <img src={Carlogo} alt="Carlogo" />
            </li>
            <li className="item">
              <img src={Vauxhall} alt="Vauxhall" />
            </li>
            <li className="item">
              <img src={BMW} alt="BMW" />
            </li>
            <li className="item">
              <img src={Ford} alt="Ford" />
            </li>
            <li className="item">
              <img src={CarlogoTwo} alt="CarLogoTwo" />
            </li>
          </ul>
        </div>
      </div>
      <div className="sell-your-car">
        <div className="inner-container">
          <div className="content">
            <h2 className="text-description">
              Sell Your Car Well.
              <span className="d-block">It’s The Car Go.</span>
            </h2>
            <p className="text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              vestibulum mi sem, ut iaculis velit scel erisque ut. Nullam
              semper, ipsum sit amet dignissim egestas, augue justo pretium
              lacus, et lacinia sapien odio gravida nibh. Aliquam sem enim,
              facilisis a commodo quis, vestibulum eu libero. Nam feugiat
              faucibus egestas. Duis leo arcu, vulputate a interdum eu, interdum
              quis eros. Vestibulum ante ipsum primis in faucibus orci luctus et
              ultrices posuere cubilia curae; Praesent vitae magna mauris.
            </p>
            <p className="text">
              Ut sagittis vulputate velit eget volutpat. Morbi pellentesque
              venenatis mollis. Nulla elementum felis nulla, sit amet placerat
              eros efficitur sit amet. Nullam id feugiat nunc. Integer sodales,
              nibh quis ornare vehicula, purus nibh bibendum lacus, quis
              imperdiet ex lectus sed diam.
            </p>
            <Button className="primary">Read More</Button>
          </div>
          <div className="featured-image">
            <img src={SellYourCar} className="width-100" alt="sell any car" />
          </div>
        </div>
      </div>
      {/* customer review */}
      <div className="customer-review">
        <div className="container">
          <div className="head">
            <h2 className="heading">
              Join Thousands of <span className="d-block">Happy Customers</span>
            </h2>
          </div>
          <div className="customers">
            <div className="customer-card">
              <div className="profile">
                <img src={Review11} alt="customer profile" />
              </div>
              <div className="review-start">
                <img src={RatingStart} alt="customer rating" />
              </div>
              <h3 className="title">Incredibly Easy Process</h3>
              <p className="text">
                Etiam consectetur erat orci. Nunc lectus quam, fer mentum sit
                amet erat placerat, accumsan consecte tur orci. Nam dolor ante,
                porttitor id quam et, consec tetur placerat lorem. Quisque massa
                .
              </p>
              <h3 className="name">Jhon Deo</h3>
              <p className="car-sold">Sold his Toyota</p>
            </div>
            <div className="customer-card">
              <div className="profile">
                <img src={Review12} alt="customer profile" />
              </div>
              <div className="review-start">
                <img src={RatingStart} alt="customer rating" />
              </div>
              <h3 className="title">Incredibly Easy Process</h3>
              <p className="text">
                Etiam consectetur erat orci. Nunc lectus quam, fer mentum sit
                amet erat placerat, accumsan consecte tur orci. Nam dolor ante,
                porttitor id quam et, consec tetur placerat lorem. Quisque massa
                .
              </p>
              <h3 className="name">Jhon Deo</h3>
              <p className="car-sold">Sold his Toyota</p>
            </div>
            <div className="customer-card">
              <div className="profile">
                <img src={Review13} alt="customer profile" />
              </div>
              <div className="review-start">
                <img src={RatingStart} alt="customer rating" />
              </div>
              <h3 className="title">Incredibly Easy Process</h3>
              <p className="text">
                Etiam consectetur erat orci. Nunc lectus quam, fer mentum sit
                amet erat placerat, accumsan consecte tur orci. Nam dolor ante,
                porttitor id quam et, consec tetur placerat lorem. Quisque massa
                .
              </p>
              <h3 className="name">Jhon Deo</h3>
              <p className="car-sold">Sold his Toyota</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
