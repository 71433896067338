import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import Path from '../../../Constants/Routes';
import { getToken } from '../../../Helpers/Helpers';
import { dealerSignUp } from '../../../Services/dealerSignup';
import Button from '../../Common/Button';
import CheckboxCircle from '../../Common/CheckboxCircle';
import CommonBanner from '../../Common/CommonBanner/CommonBanner';
import Input from '../../Common/Input';
import Popup from '../../Common/Popup/Popup';
import SelectBox from '../../Common/SelectBox/SelectBox';
import { distanceOption, noOfVehicleOption, positionOption } from './../../../Constants/optionConstant';
// import { dealerSignup } from "../../../Services/";
import './../../Auth/Auth.scss';
import { number, required, validate } from './validate';

function DealerSignup(props) {
    const { handleSubmit, pristine, reset, submitting } = props;
    // const notify = () => toast("Please enter all the field");
    const [popup, setPopup] = useState(false);
    const history = useHistory();
    const handleThankuPopup = () => {
        setPopup(false);
        history.push(Path.carListing);
    };
    // auth
    useEffect(() => {
        if (getToken()) {
            history.push(Path.carListing);
        }
        // props.initialize({ szVehicleMake: '' });
    }, [history]);

    // @dealer signup
    const handleSignup = (values) => {
        if (validate(values)) {
            dealerSignUp(props.formValues)
                .then((res) => {
                    if (res.success === false) {
                        // notify();
                    } else {
                        setPopup(true);
                        //history.push(Path.login);
                        //setFinishText(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // notify();
                });
        } else {
            console.log('please fill all the field');
        }
        // if(state.email &&)
    };
    const userOptions = [
        {
            label: 'Broker',
            value: 'Broker'
        },
        {
            label: 'Car supermarket',
            value: 'Car supermarket'
        },
        {
            label: 'Independent',
            value: 'Independent'
        },
        {
            label: 'Leasing company',
            value: 'Leasing company'
        },
        {
            label: 'Main dealer multi-franchise',
            value: 'Main dealer multi-franchise'
        },
        {
            label: 'Manufacturer',
            value: 'Manufacturer'
        }
    ];
    return (
        <div className="wrapper bg">
            <div className="dealer-signupc-main custom-scrollbar">
                <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
                    <h1 className="car-name">Dealer Signup</h1>
                </div>
                {/* <form className="auth-container-two" onSubmit={handleSubmit(handleSignup)}> */}
                <form className="auth-container-two" onSubmit={handleSubmit(handleSignup)}>
                    <div className="head">
                        <h2 className="heading">
                            <span>Personal Detail</span>
                        </h2>
                    </div>
                    <div className="auth-form-wrapper">
                        <div className="form-field w-2col">
                            <label>Name</label>
                            <Field name="szName" placeholder="e.g. John Deo" type="text" component={Input} className="width-100" validate={[required]} />
                        </div>
                        <div className="form-field w-2col">
                            <label>Company</label>
                            <Field name="szCompany" placeholder="e.g. abc LLC" type="text" component={Input} className="width-100" validate={[required]} />
                        </div>
                        <div className="form-field w-2col">
                            <label>Email</label>
                            <Field
                                name="szEmail"
                                placeholder="e.g. johndeo@gmail.com"
                                type="email"
                                component={Input}
                                className="width-100"
                                validate={[required]}
                            />
                        </div>
                        <div className="form-field w-2col">
                            <label>Contact Number</label>
                            <Field
                                name="szPhoneNumber"
                                placeholder="e.g. 7777222111"
                                type="text"
                                component={Input}
                                className="width-100"
                                validate={[required]}
                            />
                        </div>
                        <div className="form-field w-2col">
                            <label>Position</label>
                            <Field name={'szPosition'} type="text" component={SelectBox} options={positionOption} className="width-100" validate={[required]} />
                        </div>
                    </div>

                    {/* @address detail */}
                    <div className="head">
                        <h2 className="heading">
                            <span>Address Detail</span>
                        </h2>
                    </div>
                    <div className="auth-form-wrapper">
                        <div className="form-field w-3col">
                            <label>Address</label>
                            <Field
                                name="szAddress"
                                placeholder="e.g. c-13 flat-133 Newyork"
                                type="text"
                                component={Input}
                                className="width-100"
                                validate={[required]}
                            />
                        </div>
                        <div className="form-field w-3col">
                            <label>Dealer Type</label>
                            <Field
                                name="szDealerType"
                                placeholder="e.g. Dealer Type"
                                type="select"
                                component={SelectBox}
                                options={userOptions}
                                className="width-100"
                                validate={[required]}
                            />
                        </div>
                        <div className="form-field w-3col">
                            <label>Company Contact</label>
                            <Field
                                name="szCompanyPhone"
                                placeholder="e.g. +44 20 8759 9036"
                                type="text"
                                component={Input}
                                className="width-100"
                                validate={[required]}
                            />
                        </div>
                    </div>

                    {/* @Buying Preferences */}
                    <div className="head">
                        <h2 className="heading">
                            <span>Buying Preferences</span>
                        </h2>
                    </div>
                    <div className="auth-form-wrapper">
                        <div className="form-field width-100">
                            <div className="cg-radio" style={{ display: 'none' }}>
                                <Field value="" initialValues="" name="szVehicleMake" placeholder="Vehicle Make" type="text" component={Input} />
                                <label>All Make</label>
                            </div>
                        </div>
                        <div className="form-field width-100" style={{ display: 'none' }}>
                            <div className="cg-radio">
                                <Field name="szVehicleMake" placeholder="Vehicle Make" type="radio" component={Input} />
                                <label>Specific makes</label>
                            </div>
                            <div className="cg-select">
                                <select className="width-100" name="cars" id="cars" multiple>
                                    <option value="Abrath">Abrath</option>
                                    <option value="ac">AC</option>
                                    <option value="Access">Access</option>
                                    <option value="AC(Electric)">AC(Electric)</option>
                                    <option value="acura">Acura</option>
                                    <option value="adly">Adly</option>
                                    <option value="adria">Adria</option>
                                    <option value="aec">AEC</option>
                                    <option value="aixam">Aixam</option>
                                    <option value="ajs">AJS</option>
                                    <option value="ak-sportscars">Ak Sportscars</option>
                                    <option value="alexander-dennis">Alexander Dennis</option>
                                    <option value="alfa-romeo">Alfa Romeo</option>
                                    <option value="allis-chalmers">Allis Chalmers</option>
                                    <option value="all-wheel-drive">All Wheel Drive</option>
                                    <option value="alpina">Alpina</option>
                                    <option value="alpine">Alpine</option>
                                    <option value="alvis">Alvis</option>
                                    <option value="amc">AMC</option>
                                    <option value="amphicar">Amphicar</option>
                                    <option value="ams">AMS</option>
                                    <option value="aprilia">Aprilia</option>
                                    <option value="arctic-cat">Arctic Cat</option>
                                    <option value="ariel">Ariel</option>
                                    <option value="armstrong-siddeley">Armstrong Siddeley</option>
                                    <option value="ascari">Ascari</option>
                                    <option value="asia">Asia</option>
                                    <option value="aston-martin">Aston Martin</option>
                                    <option value="atala">Atala</option>
                                    <option value="atromid">Atromid</option>
                                    <option value="audi">Audi</option>
                                    <option value="austin">Austin</option>
                                    <option value="austin-healey">Austin Healey</option>
                                    <option value="auto-sleepers">Auto-Sleepers</option>
                                    <option value="auto-trail">Auto-Trail</option>
                                    <option value="autotune">Autotune</option>
                                    <option value="avelingporter">Aveling & Porter</option>
                                    <option value="avia">Avia</option>
                                    <option value="bac">BAC</option>
                                    <option value="bailey">Bailey</option>
                                    <option value="bajaj">Bajaj</option>
                                    <option value="banham">Banham</option>
                                    <option value="baotian">Baotian</option>
                                    <option value="barossa">Barossa</option>
                                    <option value="bashan">Bashan</option>
                                    <option value="bci">BCI</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-field pre-wrap width-100">
                            <label>What is the value of vehicles you typically purchase?</label>
                            <div className="pre-wrap-row">
                                <div className="pre-wrap-col">
                                    <Field
                                        name="szPriceRangeFrom"
                                        placeholder="e.g. From"
                                        type="number"
                                        component={Input}
                                        className=""
                                        validate={[required, number]}
                                    />
                                </div>
                                <div className="pre-wrap-col">
                                    <Field name="szPriceRangeTo" placeholder="e.g. To" type="number" component={Input} className="" validate={[required]} />
                                </div>
                            </div>
                        </div>
                        <div className="form-field pre-wrap width-100">
                            <label>What is the mileage of vehicles you typically purchase?</label>
                            <div className="pre-wrap-row">
                                <div className="pre-wrap-col">
                                    <Field
                                        name="szMileageRangeFrom"
                                        placeholder="e.g. From"
                                        type="number"
                                        component={Input}
                                        className=""
                                        validate={[required]}
                                    />
                                </div>
                                <div className="pre-wrap-col">
                                    <Field name="szMileageRangeTo" placeholder="e.g. To" type="number" component={Input} className="" validate={[required]} />
                                </div>
                            </div>
                        </div>
                        <div className="form-field pre-wrap width-100">
                            <label>How far will you travel to collect vehicles?</label>
                            <Field name={'szCollectVehicle'} component={SelectBox} options={distanceOption} className="width-100" validate={[required]} />
                        </div>
                        <div className="form-field pre-wrap width-100">
                            <label>How many vehicles do you purchase each month?</label>
                            <Field name={'szMonthVehicles'} component={SelectBox} options={noOfVehicleOption} className="width-100" validate={[required]} />
                        </div>
                        <div className="form-field width-100">
                            <label>Password</label>
                            <Field name="szPassword" placeholder="Password" type="password" component={Input} className="width-100" validate={[required]} />
                        </div>
                        <div className="form-field width-100">
                            <Field
                                name="iIsPrivacyPolicy"
                                id="term2"
                                component={CheckboxCircle}
                                className="circle-radio"
                                children="I have read the Privacy Policy and accept the Terms & Conditions."
                                value={1}
                                validate={[required]}
                            />
                        </div>
                        <div className="width-100">
                            <Button className="primary m-0 width-100" type="submit">
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>
                <ToastContainer autoClose={3000} />;
                <Popup onClick={handleThankuPopup} state={popup} heading="Welcome" btnText="Filter">
                    <h3>
                        Thank you very much for signing up to the Let The CarGo dealer platform. We will review your details, set up your account and confirm
                        when your account is ready to use.
                    </h3>
                    <button className="btn primary" onClick={handleThankuPopup}>
                        Ok
                    </button>
                </Popup>
            </div>
        </div>
    );
}
DealerSignup = reduxForm({
    // a unique name for the form
    form: 'dealerSignup',
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate
})(DealerSignup);

export default connect((state) => {
    const formValues = state?.form['dealerSignup']?.values;
    return {
        formValues,
        state
    };
})(DealerSignup);
