import React, { useEffect } from 'react';
import CommonBanner from '../../Components/Common/CommonBanner/CommonBanner';
import './TermsConditions.scss';

const TermsConditions = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    }, []);
    return (
        <div className="terms-conditions">
            <div class="container-terms custom-scrollbar">
                <h1 class="heading">Terms &amp; Conditions</h1>
                <h2>Auction Terms - Bidder</h2>
                <p>
                    In order to be able to Bid on a Vehicle in an Auction you need to be a registered user of the Site and hold an Account. By registering you
                    agree to be bound by these Conditions. These Conditions constitute a legally binding contract between you and Let The CarGo Auctions Limited
                    (we/us/our) and apply to your use of the Site to participate in Auctions. The Auction is intended to be used by Traders for the ultimate
                    acquisition of vehicles and by registering for a Bidder’s Account and Bidding on any vehicles you warrant that you are acting in the course
                    of business and that you are not a Consumer.
                </p>
                <p>
                    <strong>
                        You agree, acknowledge and accept that we are a vehicle listing and information service that brings together Bidders and Sellers of
                        Vehicles. We are not a party to any sale. Accordingly, we are not responsible in any way for inspecting any Vehicles or for checking the
                        correspondence of any Vehicles with their description or the quality, condition, safety or roadworthiness of any Vehicle(s) subsequently
                        purchased by you following a Bid made at any Auction on our Site.
                    </strong>
                </p>
                <p>
                    <strong>
                        You are strongly advised to conduct your own inspection of the Vehicle prior to entering into a contract for sale with the Seller. We
                        are further not responsible to you in relation to any sale contact that you may enter into with any Seller. We do not carry out any
                        checks on the identity of any Sellers.
                    </strong>
                </p>
                <p>
                    <strong>
                        All Bids placed by you are non-binding and although we hope that all who use the Services on the Site will act honourably and treat each
                        other fairly, we cannot verify the information that Sellers supply or in any way guarantee the Vehicles they offer and cannot guarantee
                        that they will proceed with the sale of the Vehicle to you, notwithstanding that you may have placed a “winning“ Bid.{' '}
                    </strong>
                </p>
                <h2 class="color-heading">
                    <span class="selnumber">1.</span> InterpreTation
                </h2>
                <p class="dsplaycls">
                    <span class="selnumber">1.1</span>Unless the context otherwise requires, the following expressions shall have the following meanings:
                </p>
                <div class="responsivetable">
                    <table border="1">
                        <tr>
                            <th>Account</th>
                            <td colspan="2">Your Let The CarGo Account, enabling you to track the progress of any Vehicles that you are selling;</td>
                        </tr>
                        <tr>
                            <th>Auction</th>
                            <td>An auction of Vehicles conducted online via our Site pursuant to these Conditions;</td>
                        </tr>
                        <tr>
                            <th>Auction End</th>
                            <td>The end of the Auction for the relevant Vehicle;</td>
                        </tr>
                        <tr>
                            <th>Auction Start</th>
                            <td>The start of the Auction, from which time Bidders may submit Bids;</td>
                        </tr>
                        <tr>
                            <th>Bid</th>
                            <td>A non-binding offer to purchase any Vehicle by bidding at Auction and “Bids” and “Bidding” shall be construed; accordingly,</td>
                        </tr>
                        <tr>
                            <th>Bidder</th>
                            <td>Any person who offers to purchase any Vehicle by bidding at Auction;</td>
                        </tr>
                        <tr>
                            <th>Buyer</th>
                            <td>The Bidder who bid the Hammer Price and subsequently enters into a contract for the purchase of the vehicle from you;</td>
                        </tr>
                        <tr>
                            <th>Conditions</th>
                            <td>These terms and conditions, as amended from time to time;</td>
                        </tr>
                        <tr>
                            <th>Consumer</th>
                            <td>
                                An individual acting for purposes which are wholly or mainly outside that individual’s trade, business, craft or profession;
                            </td>
                        </tr>
                        <tr>
                            <th>Vehicle</th>
                            <td>The vehicle that you upload to Site as available for sale via the Auction;</td>
                        </tr>
                        <tr>
                            <th>Hammer Price</th>
                            <td>The amount of the highest Bid at the Auction End;</td>
                        </tr>
                        <tr>
                            <th>Legal Age</th>
                            <td>18 years of age;</td>
                        </tr>
                        <tr>
                            <th>Listing</th>
                            <td>The description of the Vehicle on the Site setting out the main characteristics of the Vehicle;</td>
                        </tr>
                        <tr>
                            <th>Vehicle Details</th>
                            <td>
                                The details of the Vehicle as provided by you in the Listing or otherwise, including but not limited to the make and model of
                                the Vehicle, the registration number, the state, condition and roadworthiness of the Vehicle, details of any insurance claims,
                                incidents and/ or damage, the date of the last MOT, the Vehicle service history, tyre depth, interior colour and any other
                                information relating to the Vehicle that you provide together with any Reserve Price applicable to the sale of the Vehicle;
                            </td>
                        </tr>
                        <tr>
                            <th>Reserve Price</th>
                            <td>A minimum price for the Vehicle which must be achieved for a Bid to be accepted as expressly communicated by you to us;</td>
                        </tr>
                        <tr>
                            <th>Services</th>
                            <td>The services we provide to you to by displaying and auctioning your Vehicle by way of our online Auctions;</td>
                        </tr>
                        <tr>
                            <th>Site</th>
                            <td>Our website at www.letthecargo.co.uk or as amended by us from time to time;</td>
                        </tr>
                        <tr>
                            <th>Unfair Bidding Techniques</th>
                            <td>Has the meaning given in clause 3.14;</td>
                        </tr>
                        <tr>
                            <th>VAT</th>
                            <td>Value added tax; </td>
                        </tr>
                    </table>
                </div>
                <p class="dsplaycls">
                    <span class="selnumber">1.2</span>Clause and paragraph headings shall not affect the interpretation of these Conditions.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">1.3</span>A reference to an English legal term for any action, remedy, method of judicial proceeding, legal
                    document, legal status, court, official or any legal concept or thing shall, in respect of any jurisdiction other than England and Wales, be
                    deemed to include a reference to that which most nearly approximates to the English legal term in that jurisdiction.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">1.4</span>Any words following the terms including, include, in particular or any similar expression shall be
                    construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms.
                </p>
                <h2 class="color-heading">
                    <span class="selnumber">2.</span> Identity of the Parties
                </h2>
                <h2 class="color-heading1">
                    <span class="selnumber">2.1</span> Who we are
                </h2>
                <div class="list1">
                    <p>
                        <span>(a)</span> We are Let The CarGo Auctions Limited a company registered in England and Wales. Our company registration number is 13035392
                        and our registered office is at Low Hall Business Park Low Hall Road, Horsforth, Leeds, West Yorkshire, United Kingdom, LS18 4EG. Our
                        registered VAT number is 365762369.
                    </p>
                    <p>
                        <span>(b)</span> You can contact us by phone on 0113 5186900 or by email to{' '}
                        <a href="mailto:sell@letthecargo.co.uk"> sell@letthecargo.co.uk</a>.
                    </p>
                </div>
                <h2 class="color-heading1">
                    <span class="selnumber">2.2</span> You
                </h2>
                <div class="list1">
                    <p>
                        <span>(a)</span> You must register for an Account before you are able to sell any Vehicles.
                    </p>
                    <p>
                        <span>(b)</span> You warrant and represent that:
                    </p>
                    <ol>
                        <li>
                            all information provided by you to us in creating or maintaining your Account, or included within the Listing and Vehicle Details,
                            is true, complete and accurate in all respects, and that you will update any such information promptly to ensure that it remains so
                            at all times and you accept all liability for any error, omission or misrepresentation in respect of the Listing and/ or the Vehicle
                            Details and any such other description information and details;
                        </li>
                        <li>
                            you are acting in good faith in listing your Vehicle for sale on the Site and fully intend to complete the sale of the Vehicle with
                            any Buyer (provided that the Hammer Price meets or exceeds any Reserve Price that you have set on your Vehicle);
                        </li>
                        <li>that you are not selling the Vehicle as part of any trade or profession;</li>
                        <li>you are at least the Legal Age;</li>
                        <li>
                            the sale of the Vehicle by you, and the purchase of it by the Buyer from you, in your jurisdiction will not contravene any
                            applicable laws or regulations;
                        </li>
                        <li>
                            you are selling on your own behalf and you agree that you will remain liable to us and the Buyer for all your obligations and
                            liabilities under these Conditions and any contract for sale that you enter into with the Buyer;
                        </li>
                        <li>
                            you are the legal owner of the Vehicle and have full power and authority to transfer good title in the Vehicle to any Buyer making a
                            successful purchase following the Auction (as applicable), free from all third-party rights or claims; and
                        </li>
                        <li>any duties, taxes, imposts or tariffs applicable to any Vehicles offered for sale through any Auction have been paid.</li>
                    </ol>
                    <p>
                        <span>(c)</span> You will be required to give your name, address, email address and telephone number / mobile number and may be required
                        to provide satisfactory proof of identity and such other documentation relating to you and/ or the Vehicle as we may reasonably require
                        from time to time.
                    </p>
                    <p>
                        <span>(d)</span> We reserve the right to terminate or suspend your Account at any time without notice at our absolute discretion.
                    </p>
                    <p>
                        <span>(e)</span> We will hold any personal data obtained through the Site or our Services in accordance with our Privacy Notice.
                    </p>
                </div>
                <h2 class="color-heading">
                    <span class="selnumber">3.</span> Supply of Auction Services
                </h2>
                <p class="dsplaycls">
                    <span class="selnumber">3.1</span>For the avoidance of doubt, no formal contract for sale of the Vehicle is created until such time as the
                    Buyer collects the relevant Vehicle and enters into a contract with you directly for the sale and purchase of your Vehicle.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.2</span>We are a vehicle listing and information service that brings together Bidders and sellers of Vehicles. We
                    are not a party to any sale. Accordingly, we are not responsible in any way for inspecting your Vehicle or checking the correspondence of
                    any Vehicles with their description, or the quality, condition, safety or roadworthiness of any Vehicles subsequently purchased by a Buyer
                    following a bid made at any Auction on our Site.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.3</span>We are not responsible for any failure buy a Buyer to complete a sale with you and/or collect and/or pay
                    for the Vehicle and we do not carry out checks on any Bidders (including but not limited to any credit worthiness checks).
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.4</span>You are wholly responsible for satisfying yourself as to the terms of any sale agreed with a Buyer however
                    you will not release the Vehicle to the Buyer until we confirm that the Buyer has paid in full, any fees payable to us.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.5</span>If the Buyer does not pay any commission or fees due to us by the relevant due date, you authorise us to
                    terminate the impending sale and include your Vehicle in a subsequent auction.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.6</span>If you upload the Listing and complete Vehicle Details by 2pm on a business day, we will use our
                    reasonable endeavours to include your Vehicle(s) in the relevant timed Auction the following business day between 10:30 and15:30 GMT or on
                    such other date as we notify you from time to time.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.7</span>We reserve the right to remove Vehicles from the Auction at our absolute discretion, and without liability
                    to you.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.8</span>We reserve the right to amend, postpone or suspend Auctions at our discretion.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.9</span>We need certain information (including the Listing and complete Vehicle Details) from you so that we can
                    list your Vehicle(s) in the Auction. If you do not provide us with this information, we cannot include your Vehicle in an Auction and may
                    end this Contract without notice and without liability.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.10</span>Subject to these Conditions, we grant you a non-exclusive, non-transferable right, without the right to
                    grant sublicences, to use the Site to track the progress of your Vehicle(s) in Auctions.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.11</span>The Site and our Services are provided on an ‘as is’ basis and we:
                </p>
                <div class="list1">
                    <p>
                        <span>(a)</span>do not warrant that your use of the Site will be uninterrupted or error-free; or that any Services and/or the
                        information obtained by you through the Site will meet your requirements; and
                    </p>
                    <p>
                        <span>(b)</span>are not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data
                        over communications networks and facilities, including the internet, and you acknowledge that the Site may be subject to limitations,
                        delays and other problems inherent in the use of such communications facilities.
                    </p>
                </div>
                <p class="dsplaycls">
                    <span class="selnumber">3.12</span>You agree that you will:
                </p>
                <div class="list1">
                    <p>
                        <span>(a)</span>provide us with all necessary co-operation and information as we may request in respect of your use of the Site and any
                        Bids placed by Bidders in connection with your Vehicle;
                    </p>
                    <p>
                        <span>(b)</span>keep a secure password for your use of the Site and to keep such password confidential. You will not disclose this
                        password to any third party. You will not allow or suffer any third party to use your Account and will remain responsible for all
                        Listings submitted through it and activity carried out via your Account;
                    </p>
                    <p>
                        <span>(c)</span>promptly notify us of any unauthorised access to your Account that comes to your attention; and
                    </p>
                    <p>
                        <span>(d)</span>comply with your obligations under any website terms of use or policies that may be available on our Site from time to
                        time.
                    </p>
                </div>
                <p class="dsplaycls">
                    <span class="selnumber">3.13</span>You agree that you will not attempt to copy, modify, duplicate, create derivative works from, frame,
                    mirror, republish, download, transmit, or distribute all or any portion of the software that forms part of the Site, or any content thereon
                    in any form or media or by any means except, and to the extent, as may be allowed by any applicable law which is incapable of exclusion by
                    agreement between the parties.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.14</span>You agree that when uploading details about a Vehicle or otherwise using the Site, you will not knowingly
                    transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware
                    or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.15</span>You agree that you will not use or encourage any Unfair Bidding Techniques as described below. Unfair
                    Bidding Techniques are any techniques adopted by or on behalf of sellers to artificially inflate the Hammer Price achieved in an Auction,
                    including but not limited to Bidding on Vehicles where you are the seller, or organising third parties to do so (otherwise known as "shill
                    bidding").
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">3.16</span>In listing the Vehicle on the Site, you will not:
                </p>
                <div class="list1">
                    <p>
                        <span>(a)</span>establish a link to any website or other area; or
                    </p>
                    <p>
                        <span>(b)</span>include any publicity, advertisement or other statement relating to any Vehicles or services except where such link,
                        publicity, advertisement or other statement relates directly to the Vehicles which are the subject matter of such listing; and
                    </p>
                    <p>
                        <span>(c)</span>you will do nothing which would damage or otherwise affect the operation of the Site.
                    </p>
                </div>
                <h2 class="color-heading">
                    <span class="selnumber">4</span> Your Vehicle(s)
                </h2>
                <p class="dsplaycls">
                    <span class="selnumber">4.1</span>You warrant and represent that the information you provide in the Vehicle Details regarding each Vehicle
                    is true, complete and accurate.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">4.2</span>Our Site will create a Vehicle Listing based on the information you have provided. We may refuse to
                    include on the Vehicle Listing any information which we consider (in our absolute discretion) does not accurately describe the Vehicle. You
                    are responsible for ensuring that the final Vehicle Listing is true, complete and accurate.
                </p>
                <h2 class="color-heading">
                    <span class="selnumber">5</span> Complaints about our services
                </h2>
                <p class="dsplaycls">
                    <span class="selnumber">&nbsp;</span>We endeavour to provide the best experience for our Bidders and Sellers, but if you have any complaints
                    regarding our Service, please do not hesitate to contact us by telephone on 0113 5186900 or by email to sell@letthecargo.co.uk .
                </p>
                <h2 class="color-heading">
                    <span class="selnumber">6</span> Disputes between Buyer and Seller
                </h2>
                <p class="dsplaycls">
                    <span class="selnumber">&nbsp;</span>We will not get involved in or in any way attempt to resolve any dispute between you and the Buyer
                    (save to the extent that we may (at our sole discretion) provide evidence of the Listing and Vehicle Details and/ or the outcome of any
                    relevant Auction) and we are not liable for any losses, expenses, costs or otherwise incurred or suffered by you in connection with any such
                    dispute.
                </p>
                <h2 class="color-heading">
                    <span class="selnumber">7</span> Intellectual property rights
                </h2>
                <p class="dsplaycls">
                    <span class="selnumber">7.1</span>You acknowledge and agree that we (and our licensors) own all intellectual property rights in the Site and
                    the Auctions and that we do not grant you any rights to, under or in, any intellectual property rights save as expressly set out in these
                    Conditions.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">7.2</span>You will defend, indemnify and hold us harmless (and including our officers, directors and employees)
                    against any claims, actions, proceedings, losses, damages, expenses and costs (including court costs and reasonable legal fees) suffered or
                    incurred by us, arising out of or in connection with any breach of these Conditions by you.
                </p>
                <h2 class="color-heading">
                    <span class="selnumber">8</span> Limitation of Liability
                </h2>
                <p class="dsplaycls">
                    <span class="selnumber">8.1</span>Nothing in these Conditions shall limit our liability:
                </p>
                <div class="list1">
                    <p>
                        <span>(a)</span>for death or personal injury caused by our negligence; or
                    </p>
                    <p>
                        <span>(b)</span>for fraud or fraudulent misrepresentation;
                    </p>
                    <p>
                        <span>(c)</span>for any other loss or liabilities that cannot be limited or excluded by law.
                    </p>
                </div>
                <p class="dsplaycls">
                    <span class="selnumber">8.2</span>Subject to clause 8.1, you accept, acknowledge and agree that:
                </p>
                <div class="list1">
                    <p>
                        <span>(a)</span>our Services are offered to you free of charge and at your own risk;
                    </p>
                    <p>
                        <span>(b)</span>you accept sole responsibility for the legality of your actions under laws applying to you and the legality of any
                        Vehicles you list on our Site;
                    </p>
                    <p>
                        <span>(c)</span>we are not responsible for ensuring the accuracy or truthfulness of any Bidder’s or other seller’s purported identities
                        or the validity of the information which they provide to us or post on our Site;
                    </p>
                    <p>
                        <span>(d)</span>we try to keep the Site and the Services safe, secure and functioning properly, but we cannot guarantee the continuous
                        operation of or access to our Services;
                    </p>
                    <p>
                        <span>(e)</span>Bid updates and other notification functionality in our Services may not occur in real time. Such functionality is
                        subject to delays beyond our control;
                    </p>
                    <p>
                        <span>(f)</span>we shall not be liable to you in contract, tort (including negligence) or otherwise for any business losses, such as
                        loss of data, profits, revenue, business, opportunity, goodwill, reputation or business interruption or for any losses arising, directly
                        or indirectly from:
                    </p>
                    <ol>
                        <li>your use of or your inability to use our Services;</li>
                        <li>delays or disruptions in our Services;</li>
                        <li>viruses or other malicious software obtained by accessing, or linking to, our Services;</li>
                        <li>glitches, bugs, errors, or inaccuracies of any kind in our Services;</li>
                        <li>damage to your hardware device from the use of any Services or from accessing the Site;</li>
                        <li>the content, actions, or inactions of third parties, including any Bidders;</li>
                        <li>suspension or other action taken with respect to your Account; and/or</li>
                        <li>the duration or manner in which your listings appear in search results.</li>
                    </ol>
                    <p>
                        <span>(g)</span>To the extent that we are found to be liable, regardless of clause 8.(f), our liability to you or to any third party is
                        limited to 20% of the value of the Vehicle that you listed for sale on our Site and which is the subject of any dispute and where the
                        liability is not connected to a Vehicle, our liability shall be limited to £100.
                    </p>
                </div>
                <h2 class="color-heading">
                    <span class="selnumber">9</span> Other important terms
                </h2>
                <p class="dsplaycls">
                    <span class="selnumber">9.1</span>We may terminate, withdraw or suspend provision of our Services to you at any time with no obligation or
                    liability to you if the Vehicle will not be listed in the next Auction.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.2</span>If your Vehicle does not receive any Bids or any Bids meeting or exceeding the Reserve Price where
                    applicable, we may withdraw it from the subsequent Auction or include it in further Auctions at our discretion (unless we expressly agree
                    with you in writing that it shall be removed or included).
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.3</span>We have no liability to you if we are prevented from or delayed in carrying out any obligations under
                    these Conditions or from carrying out our business, by any acts, events, circumstances, omissions or accidents outside of our control.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.4</span>Your Account is personal to you, and you cannot assign, transfer, sub-contract, or otherwise deal with all
                    or any of your rights or obligations hereunder.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.5</span>We may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of
                    our rights or obligations hereunder, including in respect of any restructure or sale of our business or any part of it.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.6</span>These Conditions set out the entire agreement between us and you and supersedes and extinguishes all
                    previous agreements, promises, assurances, warranties, representations and understandings between us and you, whether written or oral,
                    relating to their subject matter.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.7</span>You acknowledge that in setting up an Account and participating in any Auctions, you do not rely on any
                    statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in these Conditions.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.8</span>We may vary these Conditions from time to time by written notice to you, any such changes will be
                    published on our Site.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.9</span>If we do not insist immediately that you do anything you are required to do under these Conditions, or if
                    we delay in taking steps against you in respect of your breach or default, that will not mean that you do not have to do those things and it
                    will not prevent us taking steps against you at a later date.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.10</span>Each of the paragraphs of these terms operates separately. If any court or relevant authority decides
                    that any of them are unlawful, the remaining paragraphs will remain in full force and effect.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.11</span>Where we are required to give you notice or tell you something we will use the email address given by you
                    to us when you set up your account, as updated by you from time to time and such notice will be deemed to have been received at the time of
                    transmission. Where we are providing general notices to all users of the Site (including where we update these Conditions) a general, but
                    reasonably prominent, posting on the Site shall be deemed to be notice to you at such time as the notice is posted.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.12</span>These Conditions, nor the legally binding contact created hereunder, do not confer any rights on any
                    person or party other than you and us (and our permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999.
                </p>
                <p class="dsplaycls">
                    <span class="selnumber">9.13</span>These Conditions are governed by English law and you can bring legal proceedings in respect of the
                    Vehicle in the English Courts. If you are a Consumer and:
                </p>
                <div class="list1">
                    <p>
                        <span>(a)</span>you live in Scotland you can bring proceedings in respect of the Vehicle in either the Scottish or English courts;
                    </p>
                    <p>
                        <span>(b)</span>you live in Northern Ireland you can bring legal proceedings in respect of the Vehicle in either the Northern Irish or
                        the English courts; or
                    </p>
                    <p>
                        <span>(c)</span>you live outside of the United Kingdom but within the European Union then you may be able to bring proceedings in your
                        local courts under the consumer protection laws applicable in your country.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsConditions;
