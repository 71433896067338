import React from "react";
import { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
// paths
import Path from "./Constants/Routes";
import { getToken } from "./Helpers/Helpers";
import CarListing from "./Pages/CarListing/CarListing";
import CustomerMessage from "./Pages/CustomerProfile/CustomerMessage/CustomerMessage";
import CustomerProfile from "./Pages/CustomerProfile/CustomerProfile";
import Home from "./Pages/Home/Home";
import UserSetting from "./Pages/UserSetting/UserSetting";
import VeshicleDetail from "./Pages/VehicleDetail/VeshicleDetail";
import DealerProcess from "./Pages/DealerProcess/DealerProcess"
import "./Styles/style.css";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  const history = useHistory();
  useEffect(() => {
    if (getToken()) {
      history.push(Path.carListing);
    }
  }, [history]);
  return (
    <>
      <PrivateRoute exact path={Path.carListing} component={CarListing} />
      {/* <Route exact path={Path.home} component={Home} /> */}
      <PrivateRoute exact path={Path.userSetting} component={UserSetting} />
      {/* <Route
      
      path={`${Path.viewBids}:slug`}
      component={ViewBids}
    /> */}
      <PrivateRoute
        exact
        path={`${Path.vehicleDetail}:slug`}
        component={VeshicleDetail}
      />

      <PrivateRoute path={Path.customerProfile} component={CustomerProfile} />
      <PrivateRoute exact path={Path.customerMessage} component={CustomerMessage} />
      <Redirect to={Path.carListing} from={Path.home}/>
      <Route exact path={Path.dealerProcess} component={DealerProcess} />
    </>
  );
};

export default Routes;
