import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import CommonBanner from "../../Components/Common/CommonBanner/CommonBanner";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import { getVehicleDetail } from "./../../Services/getVehicleDetails";
import ImageCard from "./ImageCard";
import DummyCarImg from "./../../Assets/Icons/carlist.svg";
import ProgressCard from "./ProgressCard";
import { s3URL } from "../../Constants/constants";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Swiper from "react-id-swiper";
import IMGmedium from "../../Assets/Images/IMG_9056-medium.jpg";
import IMGmedium2 from "../../Assets/Images/IMG_9057-medium.jpg";
import IMGmedium3 from "../../Assets/Images/IMG_9058-medium.jpg";
import IMGmedium4 from "../../Assets/Images/IMG_9059-medium.jpg";
import IMGmedium5 from "../../Assets/Images/IMG_9063-medium.jpg";
import Accordion from "react-bootstrap/Accordion";
import "swiper/swiper.scss";
import "./VehicleDetail.scss";
import { insertSpace } from "../../Helpers/Helpers";
import { ReactComponent as DistanceBlack } from "./../../Assets/Icons/direction.svg";
import CurrencyFormat from "react-currency-format";
import smoothscroll from "smoothscroll-polyfill";
import leftIcon from "./../../Assets/Icons/arrow-right.png";

const VeshicleDetail = (props) => {
  let { slug } = useParams();
  const history = useHistory();
  const [vehicleDetail, setVehicleDetail] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [loader, setLoader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [image, setImage] = useState("");

  const [toggleName, setToggleName] = useState(`t1`);
  const toggleupdate = (name) => {
    if (toggleName == name) {
      setToggle(!toggle);
    } else {
      setToggle(toggle);
    }
    setToggleName(name);
  };

  // Image Slider
  const swiperRef = useRef(null);
  const params = {
    slidesPerView: 3,
    spaceBetween: 4,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 4,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 4,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  };
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getVehicleDetailFun(slug);
  }, []);

  // @Vehicle details
  const getVehicleDetailFun = (slug) => {
    setLoader(true);
    getVehicleDetail(slug)
      .then(async (res) => {
        let vehD = res?.data?.data;
        if (vehD) {
          let xx = vehD?.szVehicleDetails;
          vehD.szVehicleDetails = xx ? JSON.parse(xx) : undefined;
        }
        setVehicleDetail(vehD);
        setLoader(false);
        setImage(vehicleDetail?.szExteriorFrontDriverSide);
        console.log(
          "vehicleDetailvehicleDetail",
          res?.data?.data,
          vehicleDetail
        );
        await setShowDetails(true);
      })
      .catch((err) => {
        // this.props.hideLoader();
        console.log(err);
        setLoader(false);
      });
  };

  // scroll and current image
  const setCurrentImage = (image) => {
    setImage(image);
  };

  const scrollNext = (id) => {
    document
      .getElementById(id + "slider-scroll")
      .scrollBy({ left: 100, top: 0, behavior: "smooth" });
    smoothscroll.polyfill();
  };

  const scrollPrev = (id) => {
    document
      .getElementById(id + "slider-scroll")
      .scrollBy({ left: -100, top: 0, behavior: "smooth" });
    smoothscroll.polyfill();
  };

  if (loader) {
    return <LoaderWrapper />;
  }

  const vehImgs = [
    vehicleDetail?.szExteriorFrontDriverSide,
    vehicleDetail?.szExteriorBackDriverSide,
    vehicleDetail?.szExteriorFrontPassengerSide,
    vehicleDetail?.szExteriorBackPassengerSide,
    vehicleDetail?.szInteriorFrontSeats,
    vehicleDetail?.szInteriorRearSeats,
    vehicleDetail?.szInteriorDashboard,
    vehicleDetail?.szInteriorBoot,
    vehicleDetail?.szExteriorFrontSeats,
    vehicleDetail?.szExteriorRearSeats,
    vehicleDetail?.szExteriorDashboard,
    vehicleDetail?.szInteriorFrontDriverSide,
    vehicleDetail?.szInteriorBackDriverSide,
    vehicleDetail?.szInteriorFrontPassengerSide,
    vehicleDetail?.szInteriorBackPassengerSide
  ].filter(i => !!i);

  return showDetails ? (
    <div className="vehicle-detail">
      <CommonBanner>
        <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
          <h1 className="car-name">Vehicle Details</h1>
        </div>
      </CommonBanner>

      <div className="vehicle-detail-body">
        <div className="car-detail-column-left">
          <div className="list-card">
            <div className="avatar">
              {vehImgs.length > 0
                ? <ImageCard avtar={!image ? vehImgs[0] : image} />
                : <div className="image-card">
                  <img className="avtar" src={DummyCarImg} alt="wheel" />
                </div>}
              <div className="carousel-wrapper-class">
                <div className="other-images d-flex" id={vehicleDetail.id + "slider-scroll"}>
                  {vehImgs.map(im => <span className="other-image" >
                    <img
                      className={im === image ? "active" : ""}
                      onClick={() =>
                        setCurrentImage(im)
                      }
                      src={s3URL + im} />
                  </span>)}
                  <div className="arrow">
                    <span className="arrow-left" onClick={() => scrollPrev(vehicleDetail?.id)}>
                      <img src={leftIcon} alt="Prev" />

                    </span>
                    <span className="arrow-right" onClick={() => scrollNext(vehicleDetail?.id)}>
                      <img src={leftIcon} alt="Next" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <ul className="tab d-flex list-style-none">
                <li className="title">
                    {insertSpace(vehicleDetail?.szVehicleNumber)}
                </li>
                {/* <li className="location">
                  <DistanceBlack className="icon" />
                  <span>{vehicleDetail?.szMiles} mi away</span>
                </li> */}
              </ul>
              <h2 className="card-heading">
                <span>
                  {vehicleDetail?.szVehicleDetails?.make || 'N/A'}
                </span>
              </h2>
              <h2 className="card-sub-heading" title={vehicleDetail?.szVehicleDetails?.model}>
                  {vehicleDetail?.szVehicleDetails?.model||"N/A"} &nbsp;
                  {vehicleDetail?.szVehicleDetails?.variant ?`(${vehicleDetail?.szVehicleDetails?.variant})`:"N/A"}
              </h2>
              <ul className="feature width-100 list-style-none">
                <li>
                  {vehicleDetail?.szVehicleDetails?.yearOfManufacture || 'N/A'}
                </li>
                <li>{vehicleDetail?.szMileage ? <CurrencyFormat
                  value={vehicleDetail?.szMileage}
                  displayType={"text"}
                  thousandSeparator={true}
                /> : "N/A"}</li>
                <li>{vehicleDetail?.szVehicleDetails?.fuelType || 'N/A'}</li>
                <li>{vehicleDetail?.szVehicleDetails?.colour || 'N/A'} </li>
              </ul>
            </div>
          </div>

          {window.innerWidth < 768 && (
            <div className="vehicle-detail-body mt-0">
              <div className="car-detail-column-right">
                <ProgressCard
                  data={vehicleDetail}
                  getVehicleDetailFun={getVehicleDetailFun}
                />
              </div>
            </div>
          )}

          <div className="specification">
            <div
              className={`specification-tabs ${toggle && toggleName == "t1" ? "open" : ``
                }`}
            >
              <h4 className="head" onClick={() => toggleupdate("t1")}>
                Specification
              </h4>
              <div className="tabletab">
                <table className="table">
                  <tr>
                    <td>Colour of car: </td>
                    <th>
                      <span className="code">{vehicleDetail.szCarColour}</span>
                    </th>
                  </tr>
                  <tr>
                    <td>Upholstered:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.szUpholstered}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Seats Colour:</td>
                    <th>
                      <span className="code">{vehicleDetail.szSeatsColor}</span>
                    </th>
                  </tr>
                  <tr>
                    <td>Fuel Type:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.szVehicleDetails?.fuelType}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Engine Capacity:</td>
                    <th>
                      <span className="code">
                        {
                          vehicleDetail.szVehicleDetails?.engineCapacity
                        }
                      </span>
                    </th>
                  </tr>

                  {vehicleDetail.szPatNav == 1 ?
                    <tr>
                      <td>Satnav: </td>
                      <th>
                        <span className="code">yes</span>
                      </th>
                    </tr>
                    : null}

                  {vehicleDetail.szPanoramicRoof == 1 ?
                    <tr>
                      <td>Panoramic Roof: </td>
                      <th>
                        <span className="code">yes</span>
                      </th>
                    </tr>
                    : null}

                  {vehicleDetail.szHeatedSeats == 1 ?
                    <tr>
                      <td>Heated Seats: </td>
                      <th>
                        <span className="code">yes</span>
                      </th>
                    </tr>
                    : null}

                  {vehicleDetail.szParkingCamera == 1 ?
                    <tr>
                      <td>Parking Camera: </td>
                      <th>
                        <span className="code">yes</span>
                      </th>
                    </tr>
                    : null}

                  {vehicleDetail.szUpgradedSoundSystem == 1 ?
                    <tr>
                      <td>Upgraded Sound System: </td>
                      <th>
                        <span className="code">yes</span>
                      </th>
                    </tr>
                    : null}
                </table>
              </div>
            </div>
            <div
              className={`specification-tabs ${toggle && toggleName == "t2" ? "open" : ``
                }`}
            >
              <h4 className="head" onClick={() => toggleupdate("t2")}>
                Documentation
              </h4>
              <div className="tabletab">
                <table className="table">
                  <tr>
                    <td>Registration Number: </td>
                    <th>
                      <span className="code">
                        {vehicleDetail.szVehicleNumber}
                      </span>
                    </th>
                  </tr>

                  <tr>
                    <td>First registered:</td>
                    <th>
                      <span className="code">
                        {
                          vehicleDetail.szVehicleDetails?.monthOfFirstRegistration
                        }
                      </span>
                    </th>
                  </tr>

                  <tr>
                    <td>Last MOT date:</td>
                    <th>
                      <span className="code">
                        {
                          vehicleDetail.szVehicleDetails?.motExpiryDate
                        }
                      </span>
                    </th>
                  </tr>

                  {vehicleDetail.bWrittenOff == 1 ?
                    <tr>
                      <td>Previously Written Off: </td>
                      <th>
                        <span className="code">yes</span>
                      </th>
                    </tr>
                    : null}

                  {vehicleDetail.bTwoWorkingKeys == 1 ?
                    <tr>
                      <td>Two working keys for the car: </td>
                      <th>
                        <span className="code">yes</span>
                      </th>
                    </tr>
                    : null}

                  {vehicleDetail.bV5CLogBook == 1 ?
                    <tr>
                      <td>V5C Logbook: </td>
                      <th>
                        <span className="code">yes</span>
                      </th>
                    </tr>
                    : null}

                  {vehicleDetail.bBookPack == 1 ?
                    <tr>
                      <td>Book Pack: </td>
                      <th>
                        <span className="code">yes</span>
                      </th>
                    </tr>
                    : null}
                </table>
              </div>
            </div>
            <div
              className={`specification-tabs ${toggle && toggleName == "t3" ? "open" : ``
                }`}
            >
              <h4 className="head" onClick={() => toggleupdate("t3")}>
                Service History
              </h4>
              <div className="tabletab">
                <table className="table">
                  <tr>
                    <td>Service History: </td>
                    <th>
                      <span className="code">
                        {vehicleDetail.szServiceHistoryCar ? "Yes" : "No"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Main dealer services:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.szManufacturer}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Independent garage service:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.szIndependentGarageServices}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Last service date:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.dtVehicleLastServiced}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Last service mileage:</td>
                    <th><CurrencyFormat
                      value={vehicleDetail.szLastServiceMileage || ""}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    </th>
                  </tr>
                  <tr>
                    <td>Cambelt Changed:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bCambeltChanged ? "Yes" : "No"}
                      </span>
                    </th>
                  </tr>
                </table>
              </div>
            </div>
            <div
              className={`specification-tabs ${toggle && toggleName == "t4" ? "open" : ``
                }`}
            >
              <h4 className="head" onClick={() => toggleupdate("t4")}>
                Condition & Damage
              </h4>
              <div className="tabletab">
                <table className="table">
                  <tr>
                    <td>Scratches: </td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bScratches ? "Yes" : "No"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Scuffs:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bScuffs ? "Yes" : "No"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Dents:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bDents ? "Yes" : "No"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Paintwork problems:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bPaintworkProblems ? "yes" : "no"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Broken/missing lights, mirrors, trim or fittings:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bBrokenMissing ? "yes" : "no"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Air conditioning problems:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bAirConditioningProblems ? "yes" : "no"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Mechanical or electrical faults:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bMechanicalElectricalFaults
                          ? "Yes"
                          : "No"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Warning lights on dashboard:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bDashboardWarningLights ? "yes" : "no"}
                      </span>
                    </th>
                  </tr>
                </table>
              </div>
            </div>
            <div
              className={`specification-tabs ${toggle && toggleName == "t5" ? "open" : ``
                }`}
            >
              <h4 className="head" onClick={() => toggleupdate("t5")}>
                Wheels & Tyres
              </h4>
              <div className="tabletab">
                <table className="table">
                  <tr>
                    <td>Have any of the alloys been kerbed or scuffed: </td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bDashboardWarningLights ? "Yes" : "No"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Are there any problems with the tyres:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bProblemsWithTyres ? "Yes" : "No"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Do you have the locking wheel nut:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bLockingWheelNut ? "Yes" : "No"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Do you have the tool pack:</td>
                    <th>
                      <span className="code">
                        {vehicleDetail.bToolPack ? "yes" : "no"}
                      </span>
                    </th>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="max-size">
            {/* wheels */}

            <div className="images-wrapper">
              <h3 className="head">Wheels:</h3>
              <div className="images">
                <SimpleReactLightbox className="vehicle-images-litebox">
                  <SRLWrapper>
                    {vehicleDetail?.szWheelsFrontDriverSide ? (
                      <ImageCard
                        avtar={vehicleDetail?.szWheelsFrontDriverSide}
                      />
                    ) : (
                      ""
                    )}
                    {vehicleDetail?.szWheelsBackDriverSide ? (
                      <ImageCard
                        avtar={vehicleDetail?.szWheelsBackDriverSide}
                      />
                    ) : (
                      ""
                    )}
                    {vehicleDetail?.szWheelsFrontPassengerSide ? (
                      <ImageCard
                        avtar={vehicleDetail?.szWheelsFrontPassengerSide}
                      />
                    ) : (
                      ""
                    )}
                    {vehicleDetail?.szWheelsBackPassengerSide ? (
                      <ImageCard
                        avtar={vehicleDetail?.szWheelsBackPassengerSide}
                      />
                    ) : (
                      ""
                    )}
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>

              <h3 className="head">Tyre Treads:</h3>
              <div className="images">
                <SimpleReactLightbox className="vehicle-images-litebox">
                  <SRLWrapper>
                    {vehicleDetail?.szTyreFrontPassengerSide ? (
                      <ImageCard
                        avtar={vehicleDetail?.szTyreFrontPassengerSide}
                      />
                    ) : (
                      ""
                    )}
                    {vehicleDetail?.szTyreBackPassengerSide ? (
                      <ImageCard
                        avtar={vehicleDetail?.szTyreBackPassengerSide}
                      />
                    ) : (
                      ""
                    )}
                    {vehicleDetail?.szTyreFrontDriverSide ? (
                      <ImageCard avtar={vehicleDetail?.szTyreFrontDriverSide} />
                    ) : (
                      ""
                    )}
                    {vehicleDetail?.szTyreBackDriverSide ? (
                      <ImageCard avtar={vehicleDetail?.szTyreBackDriverSide} />
                    ) : (
                      ""
                    )}
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        {window.innerWidth > 767 && (
          <div className="car-detail-column-right">
            <ProgressCard
              data={vehicleDetail}
              getVehicleDetailFun={getVehicleDetailFun}
            />
          </div>
        )}
        {/* {vehicleDetail?.szExteriorBackDriverSide ? (
          <div className="vehicle-detail-slider">
            <SimpleReactLightbox>
              <SRLWrapper>
                <Swiper {...params} ref={swiperRef}>
                  <div
                    style={{
                      display: vehicleDetail?.szExteriorBackDriverSide
                        ? "block"
                        : "none",
                    }}
                  >
                    <ImageCard
                      avtar={vehicleDetail?.szExteriorBackDriverSide}
                    />
                  </div>
                  <div
                    style={{
                      display: vehicleDetail?.szExteriorFrontDriverSide
                        ? "block"
                        : "none",
                    }}
                  >
                    <ImageCard
                      avtar={vehicleDetail?.szExteriorFrontDriverSide}
                    />
                  </div>

                  <div
                    style={{
                      display: vehicleDetail?.szExteriorBackPassengerSide
                        ? "block"
                        : "none",
                    }}
                  >
                    <ImageCard
                      avtar={vehicleDetail?.szExteriorBackPassengerSide}
                    />
                  </div>

                  <div
                    style={{
                      display: vehicleDetail?.szInteriorFrontSeats
                        ? "block"
                        : "none",
                    }}
                  >
                    <ImageCard avtar={vehicleDetail?.szInteriorFrontSeats} />
                  </div>
                  <div
                    style={{
                      display: vehicleDetail?.szInteriorDashboard
                        ? "block"
                        : "none",
                    }}
                  >
                    <ImageCard avtar={vehicleDetail?.szInteriorDashboard} />
                  </div>
                  <div
                    style={{
                      display: vehicleDetail?.szInteriorBoot ? "block" : "none",
                    }}
                  >
                    <ImageCard avtar={vehicleDetail?.szInteriorBoot} />
                  </div>
                  <div
                    style={{
                      display: vehicleDetail?.szInteriorRearSeats
                        ? "block"
                        : "none",
                    }}
                  >
                    <ImageCard avtar={vehicleDetail?.szInteriorRearSeats} />
                  </div>
                </Swiper>
              </SRLWrapper>
            </SimpleReactLightbox>
            <div className="next-prev-btn">
              <button className="swiper-button-next" onClick={goPrev}></button>
              <button className="swiper-button-prev" onClick={goNext}></button>
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  ) : (
    ""
  );
};

export default VeshicleDetail;
