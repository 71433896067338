import React from "react";
import "./RadioCircle.scss";

const CheckboxCircle = ({ input, children, className, value, id, label }) => {
  return (
    <div className={`radio ${className}`}>
      <input {...input} type="checkbox" id={id} value={value} />
      <label for={id}>
        {children}
        <span className="d-block">{label}</span>
      </label>
    </div>
  );
};

export default CheckboxCircle;
