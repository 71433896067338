import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import Button from "../../Components/Common/Button";
import Input from "../../Components/Common/Input";
import Popup from "../../Components/Common/Popup/Popup";
import SelectBox from "../../Components/Common/SelectBox/SelectBox";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import {
  distanceOption,
  noOfVehicleOption,
  positionOption,
} from "../../Constants/optionConstant";
import { store } from "../../Helpers/Helpers";
import { getDealerProfile } from "../../Services/getProfileDetail";
import { updateProfile } from "../../Services/updateProfile";
import {
  number,
  required,
  validate,
} from "./../../Components/Auth/DealerSignup/validate";
import "./EditProfile.scss";
import "./ProfileForm";

function EditProfile(props) {
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [profile, setProfileDetail] = useState([]);
  // @seller signup

  const profilUpdate = (values) => {
    validate(values);
    let payload = props.formValues;
    store.dispatch({
      type: "ADD_TODO",
      text: payload.szName,
    });
    updateProfile(payload)
      .then(async (res) => {
        if (res?.status != false) {
          localStorage.setItem("szName", payload.szName);
          getDealerDetailFun();
          setLoader(false);
          setPopup(true);
        }
      })
      .catch((err) => {
        setLoader(false);
      });

    // if(state.email &&)
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getDealerDetailFun();
  }, []);

  const handlePopup = () => {
    setPopup(!popup);
  };

  const getDealerDetailFun = () => {
    setLoader(true);
    getDealerProfile()
      .then(async (res) => {
        if (res?.success !== false) {
          setProfileDetail(res?.data?.data);
          console.log("res?.data?.data", res?.data?.data);
          await (function () {
            props.dispatch(
              change("dealerProfile", "szName", res?.data?.data?.szName)
            );
            props.dispatch(
              change("dealerProfile", "szCompany", res?.data?.data?.szCompany)
            );
            props.dispatch(
              change("dealerProfile", "szEmail", res?.data?.data?.szEmail)
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szPhoneNumber",
                res?.data?.data?.szPhoneNumber
              )
            );
            props.dispatch(
              change("dealerProfile", "szPosition", res?.data?.data?.szPosition)
            );
            props.dispatch(
              change("dealerProfile", "szAddress", res?.data?.data?.szAddress)
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szDealerType",
                res?.data?.data?.szDealerType
              )
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szCompanyPhone",
                res?.data?.data?.szCompanyPhone
              )
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szVehicleMake",
                res?.data?.data?.szVehicleMake
              )
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szMileageRangeFrom",
                res?.data?.data?.szMileageRangeFrom
              )
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szMileageRangeTo",
                res?.data?.data?.szMileageRangeTo
              )
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szPriceRangeFrom",
                res?.data?.data?.szPriceRangeFrom
              )
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szPriceRangeTo",
                res?.data?.data?.szPriceRangeTo
              )
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szCollectVehicle",
                res?.data?.data?.szCollectVehicle
              )
            );
            props.dispatch(
              change(
                "dealerProfile",
                "szMonthVehicles",
                res?.data?.data?.szMonthVehicles
              )
            );
            // updateUsername(res?.data?.data?.szFullName);
            setLoader(false);
          })();
        } else {
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const { handleSubmit, pristine, reset, submitting } = props;

  return (
    <div className="edit-profile">
      {loader && <LoaderWrapper />}
      <div className="container-edit-profile">
        <h1 className="heading">Personal Detail</h1>
        <div className="edit-profilefield">
          <form
            className="edit-profile-form"
            onSubmit={handleSubmit(profilUpdate)}
          >
            <div className="fieldset">
              <div className="form-field">
                <label>Name</label>
                <Field
                  name="szName"
                  placeholder="e.g. John Deo"
                  type="text"
                  component={Input}
                  className="width-100"
                  validate={[required]}
                />
              </div>
              <div className="form-field">
                <label>Company</label>
                <Field
                  name="szCompany"
                  placeholder="e.g. abc LLC"
                  type="text"
                  component={Input}
                  className="width-100"
                  validate={[required]}
                />
              </div>
              <div className="form-field">
                <label>Email</label>
                <Field
                  name="szEmail"
                  placeholder="e.g. johndeo@gmail.com"
                  type="email"
                  component={Input}
                  className="width-100"
                  validate={[required]}
                />
              </div>
              <div className="form-field">
                <label>Contact Number</label>
                <Field
                  name="szPhoneNumber"
                  placeholder="e.g. 7777222111"
                  type="text"
                  component={Input}
                  className="width-100"
                  validate={[required]}
                />
              </div>
              <div className="form-field">
                <label>Position</label>
                <Field
                  name={"szPosition"}
                  type="text"
                  component={SelectBox}
                  options={positionOption}
                  className="width-100"
                  validate={[required]}
                />
              </div>
            </div>
            <div className="fieldset">
              <h2 className="heading">Address Detail</h2>
              <div className="form-field">
                <label>Address</label>
                <Field
                  name="szAddress"
                  placeholder="e.g. c-13 flat-133 Newyork"
                  type="text"
                  component={Input}
                  className="width-100"
                  validate={[required]}
                />
              </div>
              <div className="form-field">
                <label>Dealer Type</label>
                <Field
                  name="szDealerType"
                  placeholder="e.g. Dealer Type"
                  type="text"
                  component={Input}
                  className="width-100"
                  validate={[required]}
                />
              </div>
              <div className="form-field">
                <label>Company Contact</label>
                <Field
                  name="szCompanyPhone"
                  placeholder="e.g. +44 20 8759 9036"
                  type="text"
                  component={Input}
                  className="width-100"
                  validate={[required]}
                />
              </div>
            </div>
            <div className="fieldset">
              <h2 className="heading">Buying Preferences</h2>
              <div className="form-field">
                <label>All Make</label>
                <Field
                  name="szVehicleMake"
                  placeholder="Vehicle Make"
                  type="text"
                  component={Input}
                  className="width-100"
                  validate={[required]}
                />
              </div>
            </div>
            <div className="fieldset">
              <div className="form-field">
                <label>
                  What is the value of vehicles you typically purchase?
                </label>
                <div className="pre-wrap-row">
                  <div className="pre-wrap-col">
                    <Field
                      name="szPriceRangeFrom"
                      placeholder="e.g. From"
                      type="number"
                      component={Input}
                      className="width-100"
                      validate={[required, number]}
                    />
                  </div>
                  <div className="pre-wrap-col">
                    <Field
                      name="szPriceRangeTo"
                      placeholder="e.g. To"
                      type="number"
                      component={Input}
                      className="width-100"
                      validate={[required]}
                    />
                  </div>
                </div>
              </div>
              <div className="form-field">
                <label>
                  What is the mileage of vehicles you typically purchase?
                </label>
                <div className="pre-wrap-row">
                  <div className="pre-wrap-col">
                    <Field
                      name="szMileageRangeFrom"
                      placeholder="e.g. From"
                      type="number"
                      component={Input}
                      className="width-100"
                      validate={[required]}
                    />
                  </div>
                  <div className="pre-wrap-col">
                    <Field
                      name="szMileageRangeTo"
                      placeholder="e.g. To"
                      type="number"
                      component={Input}
                      className="width-100"
                      validate={[required]}
                    />
                  </div>
                </div>
              </div>
              <div className="form-field">
                <label>How far will you travel to collect vehicles?</label>
                <Field
                  name={"szCollectVehicle"}
                  component={SelectBox}
                  options={distanceOption}
                  className="width-100"
                  validate={[required]}
                />
              </div>
              <div className="form-field">
                <label>How many vehicles do you purchase each month?</label>
                <Field
                  name={"szMonthVehicles"}
                  component={SelectBox}
                  options={noOfVehicleOption}
                  className="width-100"
                  validate={[required]}
                />
              </div>
            </div>
            <div className="fieldset">
              <Button className="primary" type="submit">
                Update Profile
              </Button>
            </div>
          </form>
        </div>
      </div>
      {/* popup */}
      <Popup
        onClick={handlePopup}
        // continue={continueForm}
        state={popup}
        heading="Profile"
        btnText="Filter"
        // continueText="Save later"
      >
        <p>Profile has been updated successfully.</p>
        <button className="btn primary" onClick={handlePopup}>
          Ok
        </button>
      </Popup>
    </div>
  );
}

const selector = formValueSelector("dealerProfile");
EditProfile = reduxForm({
  // a unique name for the form
  form: "dealerProfile",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(EditProfile);

export default connect((state) => {
  const formValues = state?.form["dealerProfile"]?.values;
  return {
    formValues,
    state,
  };
  // return state;
})(EditProfile);
