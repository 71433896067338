import * as moment from "moment";
import React, { useEffect, useState } from "react";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import { Link } from "react-router-dom";
import { ReactComponent as CommentIcon } from "../../Assets/Icons/comment.svg";
import { s3URL } from "../../Constants/constants";
import Path from "../../Constants/Routes";
import DummyCarImg from "./../../Assets/Icons/carlist.svg";
import { getBidList } from "./../../Services/listBids";
import ReactPaginate from "react-paginate";

import "./CustomerProfile.scss";
import CurrencyFormat from "react-currency-format";
const BidsLost = ({ data, name }) => {
  const [loader, setLoader] = useState(false);
  const [bidList, setBidList] = useState([]);
  const [count, setCount] = useState(null);

  useEffect(() => {
    let req = {
      "szStatus":"Reject"
    }
    getDealerBidList(req);
  }, []);

  // @api
  const getDealerBidList = (req) => {
    setLoader(true);
    getBidList(req)
      .then(async (res) => {
        if (res.status === false) {
          console.log("something went wrong");
        } else {
          await setBidList(res?.data?.data?.data);
          await setCount(res?.data?.data?.total);
          await setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const handlePageClick = (data) => {
    let req = {
      "szStatus":"Reject",
      "page":data.selected+1
    }
    getDealerBidList(req)
  }
  return (
    <>
    <ul className="list-style-none">  
      {loader ? <LoaderWrapper /> : null}     
      {bidList?.map((bid, index) => {
        return (
          <li key={index} className="list">
            <div className="item">
              <div className="accountVehicleCardView">
                <div className="vehiclesSection-lft">
                  <div className="photoArea">
                    <img
                      src={
                        bid?.szExteriorFrontDriverSide
                          ? s3URL + bid?.szExteriorFrontDriverSide
                          : DummyCarImg
                      }
                      alt="car logo"
                    />
                  </div>
                </div>
                <div className="vehiclesSection-rgt">
                  <div className="vrmArea">
                    <div className="vrmBadge-styles_badge">
                      <span className="text-uppercase">
                        {bid?.szVehicleNumber}
                      </span>
                    </div>
                  </div>
                  <div className="expirationArea"></div>
                  <div className="nameArea">
                    <span>{bid?.model}</span>
                  </div>
                  <div className="bidArea">
                    <span className="mw-title-title6">
                      Status:&nbsp;
                    </span>
                    <span
                        className={`${
                          bid?.szStatus ? "text-green" : "text-danger"
                        }`}
                      >
                        {bid?.szStatus}
                      </span>
                      </div>
                      <div className="bid-ammount-date">
                      <div className="bidsamount">
                    <span className="mw-title-title6">
                    Bid Placed:
                    </span>
                    <span>
                    <CurrencyFormat value={bid?.dBidAmount} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    </span>
                    </div>
                    <div className="bidsdate">
                    <span className="createdAt">                     
                      {moment(bid?.created_at).format("D/M/Y HH:mm")}
                    </span>
                  </div>
                  </div>
                  <div className="viewdetail">
                  <span className="btn primary">
                  Cancelled
                  </span>
                   
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      })}
      
    </ul>
    {!loader && bidList && bidList.length<1 && <h4 style={{ textAlign: "center" }}>No data found</h4>}
    {bidList && bidList.length <1 && count>10 && 
    <div className="pagination-wrapper">
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={count ? count / 10 : 0}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pull-right pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        // forcePage={pageNo}
      />
    </div>}
    </>
  );
};

export default BidsLost;
