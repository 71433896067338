import React from 'react';

function SelectBox({ input, className, options, meta: { touched, error } }) {
    return (
        <>
            <select {...input} className={`input ${className} ${touched && error && 'input-error'}`}>
                {options.map((item) => (
                    <option disabled={item.disabled} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
        </>
    );
}

export default SelectBox;
