import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Path from "../../../Constants/Routes";
import { getToken } from "../../../Helpers/Helpers";
import { dealerLogin } from "../../../Services/dealerLogin";
import Button from "../../Common/Button";
import CheckboxCircle from "../../Common/CheckboxCircle";
import Input from "../../Common/Input";
import LoaderWrapper from "../../Common/Loader/Loader";
import "./../../Auth/Auth.scss";
import { validate } from "./validate";

function DealerLogin(props) {
  const { handleSubmit, pristine, reset, submitting } = props;
  const [message, setMessage] = useState("");
  const [field, setField] = useState({
    szEmail: "",
    szPassword: "",
  });
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const notify = () =>
    toast.error("Please enter a valid credential", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  // auth
  useEffect(() => {
    if (getToken()) {
      history.push(Path.carListing);
    }
  }, [history]);
  // @api call
  const handleLogin = (values) => {
    setLoader(true);
    if (validate(values)) {
      dealerLogin(props.formValues)
        .then((res) => {
          if (res.success === false) {
            notify();
          } else {
            const token = res?.data?.data?.token;
            localStorage.setItem("token", token);
            localStorage.setItem("szName", res?.data?.data?.szFullName);
            setLoader(false);
            history.push(Path.carListing);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          notify();
          setLoader(false);
        });
    }
  };

  // handle Enter
  const handleEnter = (e) => {
    if (e.key == "Enter") {
      handleLogin();
    }
  };

  return (
    <>
      {loader && <LoaderWrapper />}
      <div className="login-bg">
        <div className="auth-container custom-scrollbar">
          <form
            onSubmit={handleSubmit(handleLogin)}
            className="auth-form-wrapper logincls"
          >
            <h1 className="heading ma-20px">Dealer Log in</h1>
            <h2 className="sub-heading ma-20px">Sign in to your account</h2>
            <div className="form-field">
              <label>Email address *</label>
              <Field
                name="szEmail"
                placeholder="e.g. johndeo@gmail.com"
                type="email"
                component={Input}
                className={`width-100`}
                handleKeyPress={handleEnter}
              />
              {/* {props.szEmail === undefined && (
              <span className="error">{field.szEmail}</span>
            )} */}
            </div>
            <div className="form-field">
              <label>Password *</label>
              <Field
                name="szPassword"
                placeholder="password"
                type="password"
                component={Input}
                className={`width-100`}
                handleKeyPress={handleEnter}
              />
              {/* {props.szPassword === undefined && (
              <span className="error">{field.szPassword}</span>
            )} */}
            </div>
            <div className="form-field">
              <Field
                name="iIsKeepLogedin"
                id="logedIn"
                component={CheckboxCircle}
                className="circle-radio color-radio-login"
                children="Keep me signed in on this device."
                value={1}
                // validate={[required]}
              />
              {/* <input
              className="logincheck"
              required=""
              type="checkbox"
              id="remember"
              checked=""
            /> */}
              {/* <label className="logincheck-label">
              Keep me signed in on this device.
            </label> */}
            </div>
            <Button className="primary width-100" type="submit">
              Login
            </Button>
            <div className="forgot-pass">
              <Link className="forgot-link" to={Path.forgotPassword}>
                Forgotten your password?
              </Link>
            </div>
            <div className="signUpCopy">
              New to CarGo?
              <Link className="cg-link" to={Path.signup}>
                Sign up
              </Link>
            </div>
          </form>
          <ToastContainer autoClose={3000} />
        </div>
      </div>
    </>
  );
}

const selector = formValueSelector("dealerLogin");
DealerLogin = reduxForm({
  // a unique name for the form
  form: "dealerLogin",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(DealerLogin);

export default connect((state) => {
  const formValues = state?.form["dealerLogin"]?.values;
  return {
    formValues,
    state,
  };
})(DealerLogin);
