import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getMessages } from "../../../Services/getMessageList";
import { sendQueryReply } from "../../../Services/message";
import CommonBanner from "../../../Components/Common/CommonBanner/CommonBanner";
import { Link } from "react-router-dom";
import { ReactComponent as PluseIcon } from "../../../Assets/Icons/plus.svg";
import { ReactComponent as IconSend } from "../../../Assets/Icons/Icon-send.svg";
import { ReactComponent as UserIcon } from "../../../Assets/Icons/user.svg";

import "./CustomerMessage.scss";
import { getChatDealer } from "../../../Services/getChatDealer";

const CustomerMessage = (props) => {
  const [message, setMessage] = useState("");
  const [userType, setUserType] = useState();
  const [loader, setLoader] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [dealerList, setdealerList] = useState([]);
  const history = useHistory();
  const [vehicleID, setVehicleID] = useState(history.location.state.vehicleID);
  let [dealerId, setDealerId] = useState(2);

  let { slug } = useParams();

  // const adminId = 2;
  // let dealerId = 2; //history.location.state.dealerID;

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleUserType = (event) => {
    setUserType(event.target.value);
    console.log("asdfghj", event.target.value);
  };

  const placeholderObj = {
    name: "jon",
    job: "developer",
  };

  const handleSubmit = () => {
    let idUser = dealerId;
    let payload = {
      idToUser: idUser,
      szMessage: message,
      idVehicle: vehicleID
    };
    setLoader(true);
    sendQueryReply(payload)
      .then(async (res) => {
        if (res.status === false) {
          console.log("something went wrong");
        } else {
          let req = {
            "idVehicle":vehicleID,
            "idDealer":idUser
          }
          getMessageList(req);
          setMessage("");
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getMessageList = (req) => {
    
    getMessages(req)
      .then((res) => {
        if (res?.data?.success == true) {
          setMessageList((res?.data?.data?.data).sort().reverse());
          console.log("res", res?.data?.data?.data);
        } else {
          console.log("message list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let req = {
      "idVehicle":vehicleID,
      "idDealer":2
    }
    getMessageList(req);
  }, [vehicleID]);

  useEffect(async () => {
    // getChatDealerFun(slug);

    let sellerList = [
      {
        idDealer:2,
        szDealerName:"Administrator"
      },
      {
        idDealer:history.location.state.sellerID,
        szDealerName:"Seller"
      }
    ]
    setdealerList(sellerList)
     
  }, []);

  const changeList=(idDealer)=>{
        setDealerId(idDealer);
        let req = {
          "idVehicle":vehicleID,
          "idDealer":idDealer
        }
        getMessageList(req);
  }

  return (
    <div className="customer-message">
     {/* <CommonBanner>
        <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
          <h1 className="car-name">Message</h1>
        </div>
      </CommonBanner> */}
      <div className="customer-message-wrapper">
      <div className="container-message">
      <div className="customer-message-wrapper-inner">
      <div className="customer-name-lft">
           <h3>Person</h3>
            <ul>
            {dealerList?.map((dealer, index) => (
              <li>
                <span className={dealer.idDealer==dealerId?'active':''} 
                onClick={()=>changeList(dealer.idDealer)} key={dealer} to="">
                <span><UserIcon style={{width: "18px"}}/> {dealer.szDealerName}</span>
                </span>
              </li>
            ))}
            </ul>
      </div>
      <div className="customer-message-rgt">
      <h3> Message</h3>
        <div className="customer-chat">
          <div className="customer-chat-inner">
            <div className="rano message-in">
              {messageList?.map((messageList, index) => (
                <div
                  className={`chatbox ${
                    localStorage.getItem('szName') ==
                    messageList?.fromUserName
                      ? "ml-auto"
                      : ""
                  }`}
                  key={index}
                >
                  <div className="mass-cont">
                    <div className="massadmin">
                      <span className="color-3">
                        {messageList?.fromUserName}
                      </span>
                    </div>
                    <div className="copyable-text">
                      <span>{messageList.szMessage}</span>
                    </div>
                    <div className="d-flex">
                      <span className="date ml-auto">
                        {moment(messageList?.created_at).format(
                          "Do MMMM YY, H:mm"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="chatbox ml-auto">
                <div className="mass-cont thirdperson">
                  <div className="massadmin">
                    <span className="color-3">Majid</span>
                  </div>
                  <div className="copyable-text">
                    <span>Hello Sir</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="customer-message-inner">
          <form id="mass-save-form">
          {/* <div className="masscopy-add">
            <Link>
              <PluseIcon/>
            </Link>
          </div> */}
            <div className="mass-box">
              <textarea
                id="noter-text-area"
                placeholder="Type the message to be sent"
                name="textarea"
                value={message}
                onChange={handleChange}
              />
            </div>
            <div className="submit-mass">
              <span className="send" onClick={handleSubmit}>
                <IconSend/>
              </span>
            </div>
          </form>
        </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default CustomerMessage;
