import React from "react";
import "./Input.scss";

const Input = ({
  input,
  type,
  className,
  placeholder,
  helpText,
  correct,
  meta: { touched, error, visited },
  writeOnce,
}) => {
  return (
    <>
      <input
        {...input}
        disabled={!visited && writeOnce && !input.value}
        type={type}
        placeholder={placeholder}
        className={`input ${touched && (error ? "input-error" : "")} 
        ${className}`}
      />
      {/* {touched && error && <span className="error">{error}</span>} */}
    </>
  );
};
export default Input;
