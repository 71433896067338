import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { LogoSvg } from '../../Assets/Icons';
import Path from '../../Constants/Routes';
import { getToken, store } from '../../Helpers/Helpers';
import { ReactComponent as Close } from './../../Assets/Icons/close.svg';
import { ReactComponent as MenuIcon } from './../../Assets/Icons/hamburger.svg';
import UserWhite from './../../Assets/Icons/user-white.png';
import { ReactComponent as UserSettingWhite } from './../../Assets/Icons/user-white.svg';
import User from './../../Assets/Icons/user.png';
import { ReactComponent as UserSetting } from './../../Assets/Icons/user.svg';
// @style
import './Header.scss';

const Header = () => {
    const [headerScroll, setHeaderSaroll] = useState(false);
    const [menuToggle, setToggleMenu] = useState(false);
    const [headerBG, setHeaderBG] = useState(false);
    const [auth, setAuth] = useState(false);
    const [settingMenu, setSettingMenu] = useState(false);
    const history = useHistory();
    const pathname = history.location.pathname;
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setHeaderSaroll(window.scrollY > 50);
        });
    }, []);

    useEffect(() => {
        setAuth(getToken('token'));
    });

    const location = useLocation();
    useEffect(() => {}, [location.pathname]);

    const toggleMenuClose = () => {
        setToggleMenu(false);
    };

    const toggleMenuOpen = () => {
        setToggleMenu(true);
    };

    const logout = () => {
        localStorage.clear();
        history.push(Path.home);
    };

    const toggleSettingMenuOpen = () => {
        setSettingMenu(true);
    };
    const toggleSettingMenuClose = () => {
        setSettingMenu(false);
    };

    let isHomePage = /^\/$/.test(pathname) || pathname === '/login';
    return (
        <div
            className={`header 
      ${(headerScroll && isHomePage) || !isHomePage ? 'bg-white' : ''}`}
        >
            <div className="header-top">
                <div className="container">
                    <div className="header-top-inner">
                        <div className={`customer-login ${!auth ? 'signup-bg' : ''}`}>
                            <ul className="customer-toggle">
                                {!auth ? (
                                    <li className="menu-item logincls signup-bg">
                                        <Link to={Path.signup} className={`btn align-items-center ${(headerScroll && isHomePage) || !isHomePage ? '' : ''}`}>
                                            <span>Sign Up</span>
                                        </Link>
                                    </li>
                                ) : (
                                    <li className="menu-item cursor-pointer" onClick={toggleSettingMenuOpen}>
                                        <span className="text-white profile-name text-capitalize">
                                            {store.getState() ? store.getState() : localStorage.getItem('szName')}
                                        </span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container ref d-flex align-items-center">
                <div className="logo">
                    <Link to={Path.login}>
                        <LogoSvg className="width-100" />
                    </Link>
                </div>
                <div className="ml-auto d-flex">
                    {auth && <>{window.innerWidth < 1070 ? <UserSetting className="setting home" onClick={toggleSettingMenuOpen} /> : ''}</>}
                    <MenuIcon className="menu-icon" onClick={toggleMenuOpen} />
                </div>
                <div className={`navigation ${menuToggle ? 'left-0' : 'left-minus-100'}`}>
                    <Close className="close-menu" onClick={toggleMenuClose} />
                    <ul className="list-style-none menu  d-flex align-items-center">
                        {!auth && (
                            <li className="menu-item" onClick={toggleMenuClose}>
                                <Link className="item" to={Path.login}>
                                    Home {'>'}
                                </Link>
                            </li>
                        )}
                        {auth && (
                            <li className="menu-item" onClick={toggleMenuClose}>
                                <Link className="item" to={Path.carListing}>
                                    Vehicles for Sale {'>'}
                                </Link>
                            </li>
                        )}
                        <li className="menu-item" onClick={toggleMenuClose}>
                            <Link className="item" to={Path.dealerProcess}>
                                Process {'>'}
                            </Link>
                        </li>
                        <li className="menu-item" onClick={toggleMenuClose}>
                            <Link className="item" to={Path.pricing}>
                                Pricing {'>'}
                            </Link>
                        </li>
                        {/* <li className="menu-item" onClick={toggleMenuClose}>
              <Link className="item" to={Path.customerReview}>
              Reviews {'>'}
              </Link>
            </li> */}

                        <li className="menu-item" onClick={toggleMenuClose}>
                            <Link className="item" to={Path.contactUs}>
                                Contact Us {'>'}
                            </Link>
                        </li>
                        {/* <li className="menu-item" onClick={toggleMenuClose}>
              <a
                className="item"
                href="http://cargo.synergiesoftware.com/"
                target="_blank"
              >
                For Seller >
              </a>
            </li> */}
                        <li className="menu-item email d-flex align-items-center" onClick={toggleMenuClose}>
                            <a className="item" href="mailto:buy@letthecargo.co.uk ">
                                buy@letthecargo.co.uk
                            </a>
                        </li>
                        <div className={`setting-menu ${settingMenu ? 'show' : ''}`}>
                            <span className="toggle-header d-flex">
                                <h5>User Setting</h5>
                                <Close className="icon" onClick={toggleSettingMenuClose} />
                            </span>
                            <span>
                                <div className="menu-on-large">
                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.customerProfile}>
                                        My Account
                                    </Link>
                                </div>
                                <div className="menu-on-small">
                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} exact to={Path.bidList}>
                                        Active Bids
                                    </Link>
                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} exact to={Path.bidsWon}>
                                        Bids Won
                                    </Link>
                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} exact to={Path.bidsLost}>
                                        Bids Lost
                                    </Link>
                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} exact to={Path.bidsCompleted}>
                                        Bids Completed
                                    </Link>
                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} exact to={Path.editProfile}>
                                        Edit Profile
                                    </Link>
                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} exact to={Path.changePassword}>
                                        Change Password
                                    </Link>
                                </div>
                            </span>
                            <span className="d-block" onClick={toggleSettingMenuClose}>
                                <span onClick={logout} className="logout d-block">
                                    Logout
                                </span>
                            </span>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;
