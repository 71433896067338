import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CommentIcon from "../../Assets/Icons/comment.svg";
import { s3URL } from "../../Constants/constants";
import Path from "../../Constants/Routes";
import DummyCarImg from "./../../Assets/Icons/carlist.svg";
import "./CustomerProfile.scss";
import { getBidList } from "./../../Services/listBids";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import ReactPaginate from "react-paginate";
import CurrencyFormat from "react-currency-format";
import { ReactComponent as DistanceBlack } from "./../../Assets/Icons/direction.svg";
import { insertSpace } from "../../Helpers/Helpers";
import Countdown from "react-countdown";

const BidList = ({ data, name }) => {
  const [loader, setLoader] = useState(false);
  const [bidList, setBidList] = useState([]);
  const [count, setCount] = useState(null);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    let req = {
      szStatus: "Completed",
    };
    getDealerBidList(req);
  }, []);
  // useEffect(() => {
  //   let req = {
  //     "szStatus":"Pending"
  //   }
  //   getDealerBidList(req);
  // }, []);

  // @api
  const getDealerBidList = (req = null) => {
    setLoader(true);
    getBidList(req)
      .then(async (res) => {
        if (res.status === false) {
          console.log("something went wrong");
        } else {
          await setBidList(res?.data?.data?.data.map(b=>{           
            let vehD = b?.vehicle_details;
            if(vehD){
              let xx = vehD?.szVehicleDetails;
              vehD.szVehicleDetails = xx ? JSON.parse(xx) : undefined;
            }
            return b;
          }));
          await setCount(res?.data?.data?.total);
          await setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handlePageClick = (data) => {
    let req = {
      szStatus: "Completed",
      page: data.selected + 1,
    };
    getDealerBidList(req);
  };

  return (
    <>
      <ul className="list-style-none ">
        {loader ? <LoaderWrapper /> : null}
        {bidList?.map((bid, index) => {
          console.log("bid---->", bid?.vehicle_details);
          return (
            <li key={index} className="list">
              <div className="item">
                <div className="accountVehicleCardView">
                  <div className="vehiclesSection-lft">
                    <div className="photoArea">
                      <img
                        src={
                          bid?.szExteriorFrontDriverSide
                            ? s3URL + bid?.szExteriorFrontDriverSide
                            : DummyCarImg
                        }
                        alt="car logo"
                      />
                    </div>
                  </div>
                  <div className="vehiclesSection-rgt">
                    <ul className="tab d-flex list-style-none">
                      <li className="title">{bid?.szVehicleNumber}</li>
                      {/* <li className="location">
                        <DistanceBlack className="icon" />
                        <span>175 mi away</span>
                      </li> */}
                    </ul>

                    <h2 className="card-heading">
                      {
                        bid?.vehicle_details?.szVehicleDetails?.model
                        || bid?.vehicle_details?.szVehicleDetails?.make
                        || 'N/A'
                      }
                    </h2>
                    <ul className="feature width-100 list-style-none">
                      <li>
                        {bid?.vehicle_details?.szVehicleDetails?.yearOfManufacture|| 'N/A'}
                      </li>
                      <li>
                        {
                          bid?.vehicle_details?.szMileage
                          ? <CurrencyFormat
                            value={bid?.vehicle_details?.szMileage}                                    
                            displayType={"text"}
                            thousandSeparator={true} />
                          :"N/A"
                        }
                      </li>
                      <li>
                        {bid?.vehicle_details?.szVehicleDetails?.fuelType|| 'N/A'}
                      </li>
                      <li>{bid?.vehicle_details?.szVehicleDetails?.colour|| 'N/A'}</li>
                    </ul>

                    <div className="bid-ammount-date">
                      <div className="bid-status">
                        <span className="mw-title-title6">Status:&nbsp;</span>
                        <span
                          className={`${
                            bid?.szStatus ? "text-green" : "text-danger"
                          }`}
                        >
                          {bid?.szStatus}
                        </span>
                      </div>
                      <div className="bid-status">
                        <span className="mw-title-title6">Bid Placed:&nbsp;</span>
                        <span className="text-green">
                          <CurrencyFormat
                            value={bid?.dBidAmount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"£"}
                          />
                        </span>
                      </div>
                      <div className="bid-status">
                        <span className="createdAt">
                          {moment(bid?.created_at).format("D/M/Y HH:mm")}
                        </span>
                      </div>

                      {/* <Link
                      className="CommentIcon-cls"
                      to={{
                        pathname: Path.customerMessage,
                        state: {
                          vehicleID: bid?.idVehicle,
                          sellerID: bid?.idSeller,
                        },
                      }}
                    >
                      <span>
                        <img src={CommentIcon} alt="Message" />
                      </span>
                    </Link> */}
                    </div>
                    {/* <div className="aution-date">
                      <Countdown
                        date={
                          Date.now() +
                          moment(
                            bid?.vehicle_details?.dtAuctionEndDate,
                            "YYYY-MM-DD"
                          ).diff(moment())
                        }
                      />
                    </div> */}
                    {/* <div className="viewdetail">
                      <Link
                        className="btn primary"
                        to={Path.vehicleDetail + bid?.idVehicle}
                      >
                        REVISE BID
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {!loader && bidList && bidList.length < 1 && (
        <h4 style={{ textAlign: "center" }}>
          There are currently no active bids placed
        </h4>
      )}
      {bidList && bidList.length < 1 && count > 10 && (
        <div className="pagination-wrapper">
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={count ? count / 10 : 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pull-right pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            // forcePage={pageNo}
          />
        </div>
      )}
    </>
  );
};

export default BidList;
