import React from 'react';
import Selleprossec1 from '../../Assets/Images/selleprossec1.png';
import Vectorsmart2 from '../../Assets/Images/vectorsmart2.png';
import Vectorsmart3 from '../../Assets/Images/vectorsmart3.png';
import './DealerProcess.scss';

const DealerProcess = () => {
    return (
        <div className="dealerprocess-conditions">
            <div className="container-dealerprocess custom-scrollbar">
                <div className="headtitle">
                    <h1>Dealer Process</h1>
                </div>
                <div className="dealerprocess-inner">
                    <ul>
                        <li>
                            <div className="process">
                                <div className="process-inner">
                                    <div className="pro-number">
                                        <span>1.</span>
                                    </div>
                                    <div className="process-img">
                                        <img className="company-team" src={Selleprossec1} alt="Dealer Process 1" />
                                    </div>
                                    <div className="process-title">
                                        <h3>Sign up to our system</h3>
                                    </div>
                                    <div className="process-dis">
                                        Joining Let The CarGo is fast and easy, not to mention free. Once approved, you’ll have immediate browsing access to a
                                        supply of privately-owned vehicles you won’t find anywhere else. Our platform is built for speed and simplicity, so
                                        you’ll be acquiring cars for your showroom and forecourt in no time. We provide constant support and clear lines of
                                        communication to our team to guide you through if you need it.
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="pro-mobile">
                            <div className="process">
                                <div className="process-inner">
                                    <div className="pro-number">
                                        <span>2.</span>
                                    </div>
                                    <div className="process-img">
                                        <img className="company-team" src={Vectorsmart2} alt="Dealer Process 2" />
                                    </div>
                                    <div className="process-title">
                                        <h3>Jumping into the auction</h3>
                                    </div>
                                    <div className="process-dis">
                                        Only cars that are meticulously evaluated by our expert staff are permitted for sale. We delve into the details to
                                        ensure there are no issues when it comes to completing the trade and go the distance to guarantee as much transparency
                                        as possible. Using the latest datasets, and sometimes our own discretion, we fix a guide value that is fair for
                                        everyone. Exploring the auctions on your device is effortless and allows total mastery of the bidding by employing our
                                        automated proxy option to set your maximum price.
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="process">
                                <div className="process-inner">
                                    <div className="pro-number">
                                        <span>3.</span>
                                    </div>
                                    <div className="process-img">
                                        <img className="company-team" src={Vectorsmart3} alt="Dealer Process 3" />
                                    </div>
                                    <div className="process-title">
                                        <h3>Closing the sale</h3>
                                    </div>
                                    <div className="process-dis">
                                        After the outcome of a successful bid, we’ll confirm the transaction with the seller and introduce you to arrange for a
                                        secure payment and handover to take place. Let The CarGo is developed by dealers based on our own mindset and vision for
                                        the future. We represent a superior and more streamlined alternative to traditional stock acquisition, reducing the
                                        inconvenience of scouring the market while simultaneously helping our partners to evolve,modernise and reach utmost
                                        efficiency.
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li className="pro-desktop">
                            <div className="process">
                                <div className="process-inner">
                                    <div className="pro-number">
                                        <span>2.</span>
                                    </div>
                                    <div className="process-img">
                                        <img className="company-team" src={Vectorsmart2} alt="Dealer Process 2" />
                                    </div>
                                    <div className="process-title">
                                        <h3>Jumping into the auction</h3>
                                    </div>
                                    <div className="process-dis">
                                        Only cars that are meticulously evaluated by our expert staff are permitted for sale. We delve into the details to
                                        ensure there are no issues when it comes to completing the trade and go the distance to guarantee as much transparency
                                        as possible. Using the latest datasets, and sometimes our own discretion, we fix a guide value that is fair for
                                        everyone. Exploring the auctions on your device is effortless and allows total mastery of the bidding by employing our
                                        automated proxy option to set your maximum price.
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DealerProcess;
