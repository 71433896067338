import moment from 'moment';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../Components/Common/Button';
import InputCustom from '../../Components/Common/Input/InputCustom';
import LoaderWrapper from '../../Components/Common/Loader/Loader';
import Popup from '../../Components/Common/Popup/Popup';
import { ReactComponent as Successfull } from './../../Assets/Icons/accepted.svg';
import Icon from './../../Assets/Icons/info-icon.png';
import CurrencyFormat from 'react-currency-format';
import { addBid } from './../../Services/addBid';
import { useParams } from 'react-router-dom';
import 'moment-timezone';

const ProgressCard = (props) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [showPopup, setShowPopup] = useState('');
    const [showPopupBuyNow, setshowPopupBuyNow] = useState('');
    const [isSoldOut, setSoldOut] = useState(false);
    const [isReSubmit, setReSubmit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [BuyNowConfirm, setBuyNowConfirm] = useState('');
    const [errorMessage, setErrorMessageMessage] = useState('');
    const [failedSold, setFailedSold] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [fee, setFee] = useState(false);

    const [toggle2, setValuationsToggle] = useState(false);
    const [autofocus, setAutofocus] = useState(false);
    const [popup, setPopup] = useState(false);

    let { slug } = useParams();
    //valuations toggle

    const valuationstoggle = () => {
        setValuationsToggle(!toggle2);
    };

    // handleChange
    const handleChange = (value) => {
        setValue(value);
        if (!value || !value.trim()) {
            setError('Enter Car Number');
        } else {
            setError('');
        }
    };

    const handleThankuPopup = () => {
        setPopup(false);
    };

    // handle bid popup
    const handleBidSuccess = () => {
        setShowPopup(!showPopup);
        props.getVehicleDetailFun(slug);
        document.getElementById('myTextField').value = '';
        document.getElementById('myTextField').focus();
    };

    const handleBidSuccessBuyNOw = () => {
        setSoldOut(true);
        setshowPopupBuyNow(!showPopupBuyNow);
    };

    const calculateFee = () => {
        let tempFee = '';
        if (value <= 4999) {
            tempFee = 200;
        }
        if (value > 5000 && value <= 9999) {
            tempFee = 225;
        }
        if (value > 10000 && value <= 14999) {
            tempFee = 245;
        }
        if (value > 15000 && value <= 19999) {
            tempFee = 295;
        }
        if (value > 20000 && value <= 29999) {
            tempFee = 345;
        }
        if (value > 30000 && value <= 39999) {
            tempFee = 395;
        }
        if (value > 40000 && value <= 49999) {
            tempFee = 445;
        }
        if (value > 50000 && value <= 99999) {
            tempFee = 695;
        }
        if (value > 100000) {
            tempFee = 945;
        }
        setFee(tempFee);
        setConfirmation(true);
    };

    const showConfirmation = () => {
        setConfirmation(false);
        bidReqFun();
    };
    const bidReqFun = () => {
        let req = { idVehicle: props.data.id, dBidAmount: value };

        if (value >= props.data.dBuyNowPrice && props.data.dBuyNowPrice > 0) {
            buyNowFun(true);
        } else {
            setLoader(true);
            addBid(req)
                .then((res) => {
                    setReSubmit(true);
                    document.getElementById('myTextField').value = '';
                    setShowPopup(true);
                    setLoader(false);
                })
                .catch((err) => {
                    setShowPopup(false);
                    setLoader(false);
                    setErrorMessageMessage(err?.message);
                    setFailedSold(true);
                });
        }
    };

    const buyNowFun = (isMaxPrice = false) => {
        let req = {
            idVehicle: props.data.id,
            dBidAmount: props.data.dBuyNowPrice,
            bBuyNow: true
        };
        setLoader(true);
        addBid(req)
            .then((res) => {
                // console.log(res);
                // await setMessage(res?.data?.message)
                notify(res?.data?.message);
                setLoader(false);
                setSoldOut(true);
                setBuyNowConfirm(false);
            })
            .catch((err) => {
                // notify(err?.message);
                // toast.error(err?.message, { position: toast.POSITION.TOP_RIGHT });
                setErrorMessageMessage(err?.message);
                setFailedSold(true);
                setBuyNowConfirm(false);
                // this.props.hideLoader();
                setLoader(false);
                console.log(err);
            });
    };

    const handleBuyNowConfirm = () => {
        setBuyNowConfirm(true);
    };

    const handleSetFailedSold = () => {
        setFailedSold(false);
        document.getElementById('myTextField').focus();
    };

    const handleSetConfirmation = () => {
        setConfirmation(false);
    };

    const notify = (message) =>
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    const TimeDiff = (inputDate) => {
        if (moment.tz(inputDate, 'Europe/London').format('z') === 'BST') {
            return moment.utc(inputDate, 'YYYY-MM-DD HH:mm:ss').add(-1, 'hours').diff(moment().utc());
        } else return moment.utc(inputDate, 'YYYY-MM-DD HH:mm:ss').diff(moment().utc());
    };
    return (
        <>
            {loader && <LoaderWrapper />}
            <div className="progress-card">
                <h3 className="card-heading">{props.data.szStatus == 'Won' ? 'Auction Sold' : 'Auction in Progress'}</h3>
                <div className="all-price">
                    <div className="heading-price">
                        <span className="cap">Start Price:</span>
                        <span className="price">
                            <CurrencyFormat value={props?.data?.dMinimumPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                        </span>
                    </div>
                    <div className="heading-price">
                        {props?.data?.TradeClean ? (
                            <>
                                <span className="cap">CAP Clean:</span>
                                <span className="price">
                                    <CurrencyFormat value={props?.data?.TradeClean} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                    </div>

                    {/* <div className="heading-price">
            {props?.data?.dMinimumPrice ? (
              <>
                <span className="cap">Min Bid:</span>
                <span className="price">
                  <CurrencyFormat
                    value={props?.data?.dMinimumPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                  />
                </span>
              </>
            ) : (
              ""
            )}
                  </div> */}
                    <div className="heading-price">
                        {props?.data?.seller_details?.szPostCode ? (
                            <>
                                <span className="cap">Location:</span>
                                <span
                                    className="price"
                                    onClick={() => {
                                        setPopup(true);
                                    }}
                                >
                                    {props?.data?.seller_details?.szPostCode}
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <ul className="list-style-none form-list">
                    {props?.data?.dtAuctionEndDate && !isSoldOut && props.data.szStatus == 'Pending' && (
                        <li className="aution-end-date">
                            <label>Auction Ends:</label>
                            <span>
                                <Countdown date={Date.now() + TimeDiff(props?.data?.dtAuctionEndDate)} />
                                <span className="dim">{props?.data?.iBidCount}</span>
                            </span>
                        </li>
                    )}
                    {props?.data?.bidList.length ? (
                        <div className={`valuations-cg ${toggle2 ? 'active' : ``}`}>
                            <div className="valuations-title" onClick={() => valuationstoggle()}>
                                <span>Previous Bids</span>
                            </div>
                            <div className="valuations" id="valuations">
                                <ul>
                                    {props?.data?.bidList?.map((bid, index) => (
                                        <li>
                                            <span>{moment(bid?.created_at).format('D/M/Y HH:mm')}</span>
                                            <span>
                                                <CurrencyFormat value={bid?.dBidAmount} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {!isSoldOut && props.data.szStatus == 'Pending' && (
                        <li className="flex-column">
                            <span className="d-block mr-auto ml-0">Enter Bid Amount</span>
                            <InputCustom placeholder="&#163;" type="number" id="myTextField" onChange={(e) => handleChange(e.target.value)} />

                            <Button onClick={calculateFee} className="primary btn">
                                {isReSubmit || props?.data?.isReSubmit ? 'Resubmit bid' : 'Enter bid'}
                            </Button>
                            {/* {props.data.dBuyNowPrice ? (
                <Button
                  onClick={handleBuyNowConfirm}
                  className="primary btn black"
                >
                  Buy Now
                </Button>
              ) : (
                ""
              )} */}
                        </li>
                    )}
                </ul>

                <ToastContainer autoClose={7000} />
            </div>

            <Popup state={confirmation} heading="Bid Confirmation" onClick={handleSetConfirmation}>
                <div className="successfull-message">
                    <h4>Please click the Confirm button to place bid.</h4>
                    <h4>You will be charged £{fee} (+VAT) to buy this vehicle if your bid is successful.</h4>
                    <Button className="primary" onClick={showConfirmation}>
                        Confirm
                    </Button>
                </div>
            </Popup>
            <Popup state={failedSold} heading="Error" onClick={handleSetFailedSold}>
                <div className="successfull-message">
                    <h4>{errorMessage}</h4>

                    <Button className="primary" onClick={handleSetFailedSold}>
                        Ok
                    </Button>
                </div>
            </Popup>
            <Popup state={showPopup} heading="Bid Submited" onClick={handleBidSuccess}>
                <div className="successfull-message">
                    {/* <Successfull className="icon" /> */}
                    <h4>Your bid has been submitted successfully</h4>

                    <Button className="primary" onClick={handleBidSuccess}>
                        Ok
                    </Button>
                </div>
            </Popup>
            <Popup state={showPopupBuyNow} heading="Buy Vehicle" onClick={handleBidSuccessBuyNOw}>
                <div className="successfull-message">
                    <Successfull className="icon" />
                    <h4>You have successfully bought the vehicle.</h4>

                    <Button className="primary" onClick={handleBidSuccessBuyNOw}>
                        Ok
                    </Button>
                </div>
            </Popup>
            {/* buy now popup */}
            <Popup state={BuyNowConfirm} heading="Buy Now" onClick={() => setBuyNowConfirm(false)} additionalClass="bynow-popup">
                <div className="successfull-message">
                    <h4 className="text-buynow">
                        You are agreeing to buy the vehicle for
                        <span> £{props.data.dBuyNowPrice}. </span>
                        Clicking the buy now button below would allow you to buy the vehicle. Please click the Cancel button if you don't want to buy the
                        vehicle for <span>£{props.data.dBuyNowPrice}.</span>
                    </h4>
                    <Button className="primary" onClick={buyNowFun}>
                        Buy
                    </Button>
                    <Button className="primary" onClick={() => setBuyNowConfirm(false)}>
                        Cancel
                    </Button>
                </div>
            </Popup>
            <Popup onClick={handleThankuPopup} state={popup} heading="Map" btnText="Filter" additionalClass="map_CarGrid">
                <iframe
                    style={{ top: 0, border: '0px', height: 'calc(100vh - 230px)', width: '100%' }}
                    allowfullscreen
                    src={`https://maps.google.com/maps?q=${props?.data?.seller_details?.szPostCode}&output=embed`}
                ></iframe>
            </Popup>
        </>
    );
};

export default ProgressCard;
