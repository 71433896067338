import React, { useEffect } from 'react';
import CommonBanner from '../../Components/Common/CommonBanner/CommonBanner';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    }, []);
    return (
        <div className="privacy-policy">
            <div class="container-privacy custom-scrollbar">
                <h1 class="heading">Privacy Policy</h1>
                <p>
                    <strong>Dated: June 2021</strong>
                </p>
                <ol>
                    <li>
                        <strong> Who is responsible for managing my information?</strong>
                    </li>
                </ol>
                <p>
                    <strong>Let The CarGo </strong>&nbsp;and all websites&nbsp; operated and/or produced in relation to it are owned and maintained by
                </p>
                <p>
                    <strong>Let The CarGo Auctions Limited</strong>
                </p>
                <p>Low Hall Business Park, Horsforth, United Kingdom, LS18 4EG</p>
                <p>Company Number: 13035392</p>
                <p>
                    &nbsp;("<strong>we</strong>" or "<strong>us</strong>")
                </p>
                <p>This is our Privacy Notice and it explains how we collect, use, share and protect your personal data.</p>
                <p>
                    We are responsible for the collection and proper management of any personal information you submit. We will keep your personal details
                    secure and use the information you provide consistently with applicable privacy and data protection laws and the terms of this Privacy
                    Notice.
                </p>
                <ol start="2">
                    <li>
                        <strong> What information do we collect?</strong>
                    </li>
                </ol>
                <p>
                    If you choose to buy, bid for, or sell vehicles through us or make a query about buying, bidding for, or selling vehicles through us we will
                    collect details that you provide in relation to that sale, bid, purchase or query.
                </p>
                <p>
                    The information we collect is likely to include your name and contact details, address, bank details, date of birth, information about any
                    vehicle you offer for sale or which you bid for or purchase through us, details of any transaction you make through us, details of any
                    queries you raise &nbsp;and any additional information we may need to help meet your specific requirements.
                </p>
                <p>
                    We also collect website usage information and do this using “cookies”.&nbsp; For further information about cookies and how we use them
                    please see our Cookie Policy.
                </p>
                <ol start="3">
                    <li>
                        <strong> How do we use your information?</strong>
                    </li>
                </ol>
                <p>The information you provide may be used in a number of ways, for example:</p>
                <ul class="leftd">
                    <li>to enable us to facilitate the sale by auction of your vehicle(s)</li>
                    <li>to facilitate your bidding for and/or purchasing of vehicles through our auction site(s)</li>
                    <li>to enable us to provide services as auctioneers of motor vehicles</li>
                    <li>to enable us to provide related support services;</li>
                    <li>for statistical purposes when we evaluate our services;</li>
                    <li>to personalise repeat visits to our websites or inform you about issues;</li>
                    <li>to tell you about other products and services we think may be of interest to you (see below);</li>
                    <li>to manage customer service queries;</li>
                    <li>to help us to provide details or information you may have requested; and</li>
                    <li>to allow us to comply with our legal obligations.</li>
                </ul>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <ol start="4">
                    <li>
                        <strong> On what legal basis will we process your information?</strong>
                    </li>
                </ol>
                <p>We will always process your personal information on lawful grounds and in particular on the grounds set out below:-</p>
                <p>
                    <strong>Performance of a Contract/ Taking steps at your request prior to entering into a Contract</strong>
                </p>
                <p>
                    We provide services to you as online auctioneers of motor vehicles. If you use our services to offer vehicles for sale or to look for, bid
                    for or purchase vehicles we will use the details that you give to provide those services &nbsp;to you. This information is likely to include
                    your name, details of any vehicle you offer for sale, bid for, or purchase, your date of birth,address and &nbsp;contact details, payment
                    and bank details, information relating to &nbsp;any transaction we facilitate and any additional information we may need to help meet your
                    specific requirements and to provide the services you require.
                </p>
                <p>If you do not provide us with the information set out in this paragraph, then we may not be able to provide our services to you.</p>
                <p>
                    <strong>Legitimate Interest</strong>
                </p>
                <p>We want to give you the best possible customer experience whilst respecting your privacy.</p>
                <p>
                    We may use your name and contact details to tell you about our own products and services which we think may be of interest to you.{' '}
                    <strong>You can ask us to stop doing so at any time</strong>.
                </p>
                <p>We may also use some of your personal information for statistical purposes when we evaluate our range of services.</p>
                <p>
                    We may share your contact information with third party market research companies who may contact you to conduct market research about our
                    products and services.
                </p>
                <p>
                    We may share your email address with our technology providers including Google, Facebook and others (acting as data processors) for them to
                    use on our behalf in connection with the creation of marketing and promotion for us.
                </p>
                <p>
                    They may use this to serve targeted advertising/content to you via their platforms. You can control what advertisements you receive via the
                    privacy settings on the relevant platform.
                </p>
                <p>
                    Google and Facebook may also use socio-demographic information about you to identify other people like you, with similar interests to
                    present our marketing to. This socio-demographic information is not used for any other purpose nor shared with any other party.&nbsp; No
                    information about other individuals identified from these networks is shared with us at any time.
                </p>
                <p>
                    Data privacy law allows this as part of our legitimate interest in understanding our customers, marketing our services to them &nbsp;and
                    helping us to provide them with the highest levels of service.
                </p>
                <p>
                    We may also make use of your personal data to the extent required to establish, exercise or defend our legal rights and to take legal
                    advice.
                </p>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <p>
                    <strong>Consent</strong>
                </p>
                <p>
                    We may ask your consent to share your name and contact details with selected third parties companies so that they can tell you about their
                    products and services.
                </p>
                <p>If you agree, we may also pass on your information, in certain cases, for the purposes of conducting a credit check.</p>
                <p>
                    <strong>
                        <em>You can withdraw your consent at any time</em>
                    </strong>
                    <em>.</em>
                </p>
                <p>
                    <strong>Legal Obligation and Compliance</strong>
                </p>
                <p>
                    We may also use, process and disclose your personal data for compliance purposes, as required by any legal, regulatory, law enforcement,
                    licensing or taxation authority or body and to the extent that we are required to do so by law, regulation or court order.
                </p>
                <ol start="5">
                    <li>
                        <strong> Who will you share my information with?</strong>
                    </li>
                </ol>
                <p>In order to provide you with the products and services that we offer and/or which you have ordered we may share your information with:</p>
                <ul class="leftd">
                    <li>if you are offering a vehicle (or vehicles) for sale, with the purchasers or potential purchasers of such vehicles</li>
                    <li>if you seeking to purchase or bid for vehicles, with the owners or sellers of those vehicles</li>
                    <li>other members of our group of companies and their service providers (see also section 6 below);</li>
                    <li>banks, payment facilitators and other providers of financial services</li>
                    <li>credit/debit card companies;</li>
                </ul>
                <p>
                    We may also use and disclose your personal data <em>without your permission</em>:-
                </p>
                <ul class="leftd">
                    <li>in connection with any legal proceedings or prospective legal proceedings,</li>
                    <li>to legal, justicial, regulatory, licensing, law enforcement, public health and taxation authorities,</li>
                    <li>to prevent crime;</li>
                    <li>to take legal advice; and</li>
                    <li>to establish, exercise or defend our legal rights</li>
                </ul>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <ol start="6">
                    <li>
                        <strong> How do you manage my information?</strong>
                    </li>
                </ol>
                <p>
                    We may from time to time appoint other members of our group of companies, or third party service providers, to help us manage efficient
                    systems within the business. Some of these systems may for operational reasons be located in countries overseas including the USA.
                </p>
                <p>
                    We will only transfer your information to service providers who help manage our systems, or overseas, where we are satisfied that this is
                    lawful and that adequate levels of protection are in place to protect the integrity and security of any information being processed and in
                    compliance with applicable privacy laws.
                </p>
                <p>If we transfer your personal data to service providers outside the United Kingdom then we will always use a lawful method to do so.</p>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <ol start="7">
                    <li>
                        <strong> Updating Your Details?</strong>
                    </li>
                </ol>
                <p>
                    If you would like to change any of your preferences relating to the way in which we may use your information for direct marketing, then
                    please update your user preferences or send an email to&nbsp;&nbsp; support@letthecargo.co.uk or contact us at 0113 5186900.
                </p>
                <ol start="8">
                    <li>
                        <strong> How long will we hold your information for?</strong>
                    </li>
                </ol>
                <p>
                    We have a system of retention periods in place to ensure that your information is only stored whilst it is required for the relevant
                    purposes or to meet legal requirements. Where your information is no longer required, we will ensure it is disposed of or deleted in a
                    secure manner.
                </p>
                <ol start="9">
                    <li>
                        <strong> Your rights </strong>
                    </li>
                </ol>
                <p>You have the right to ask us at any time:-</p>
                <ul class="leftd">
                    <li>to confirm whether we hold any of your personal data;</li>
                    <li>to send you a copy of any personal data that we hold about you;</li>
                    <li>to correct any inaccuracies in your personal data and to add relevant details where the personal data we hold is incomplete;</li>
                    <li>to delete (to the extent possible) any of your personal data, where we are required by law to do so;</li>
                    <li>to stop processing your personal data, where we are required by law to do so;</li>
                    <li>to let you have a portable copy of the personal data we hold about you, where we are required by law to do so;</li>
                    <li>to stop processing any of your personal data that we process on the basis of our legitimate interests; and</li>
                    <li>
                        to stop sending you marketing material. However please note that we may continue to send you service related (i.e. non-marketing)
                        communications, such as email updates
                    </li>
                </ul>
                <p>
                    Where we have reached a decision that affects you by processing your personal data automatically then you have the right to speak to someone
                    to discuss that decision.
                </p>
                <p>We do not use your personal data for the purpose of profiling.</p>
                <p>
                    Where we process your personal data on the basis that you have given us your consent to do so then you may contact us at any time to
                    withdraw your consent
                </p>
                <p>
                    If you wish to exercise any of these rights, or wish to object to our use of your personal information, please write to us at the address
                    given below.
                </p>
                <p>Data Protection Co-ordinator</p>
                <p>
                    Let The CarGo Auctions Limited
                    <br /> Low Hall, Business Park, Horsforth, Leeds LS18 4EG
                </p>
                <p>Or by email to: &nbsp;support@letthecargo.co.uk</p>
                <ol start="10">
                    <li>
                        <strong> Does the notice apply to linked websites?</strong>
                    </li>
                </ol>
                <p>
                    Our websites may contain links to other websites. This privacy notice only applies to this website so when you link to other websites you
                    should read their own privacy policies/notices.
                </p>
                <ol start="11">
                    <li>
                        <strong> When do you monitor telephone calls?</strong>
                    </li>
                </ol>
                <p>
                    We may from time to time record telephone calls you have with members or our staff for training purposes and to improve the quality of our
                    service.
                </p>
                <ol start="12">
                    <li>
                        <strong> How do we update this privacy notice?</strong>
                    </li>
                </ol>
                <p>We keep our privacy notice under regular review and we will place any updates on our webpage.</p>
                <ol start="13">
                    <li>
                        <strong> Who can I contact if I have queries about this privacy notice?</strong>
                    </li>
                </ol>
                <p>You can also contact us directly if you have any questions about our privacy notice or information we hold about you.</p>
                <p>Please write to us at the address given below.</p>
                <p>Data Protection Co-ordinator</p>
                <p>Low Hall, Business Park, Horsforth, Leeds LS18 4EG</p>
                <p>United Kingdom.</p>
                <p>Or by email to: support@letthecargo.co.uk</p>
                <p>Or call on: 0113 5186900</p>
                <p>
                    <strong>
                        If you are dissatisfied with our handling of any complaint you also have the right to raise concerns with The UK Information
                        Commissioner:&nbsp; https://ico.org.uk
                    </strong>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
