import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../Components/Common/Button';
import CommonBanner from '../../Components/Common/CommonBanner/CommonBanner';
import LoaderWrapper from '../../Components/Common/Loader/Loader';
import Popup from '../../Components/Common/Popup/Popup';
import Path from '../../Constants/Routes';
import ImageCard from '../VehicleDetail/ImageCard';
import { listAllVehicle } from '../../Services/vehicleList';
import CurrencyFormat from 'react-currency-format';

import './CarListing.scss';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { ReactComponent as CloseBlack } from './../../Assets/Icons/close.svg';
import { ReactComponent as DistanceBlack } from './../../Assets/Icons/direction.svg';
import { s3URL } from '../../Constants/constants';
import { insertSpace } from '../../Helpers/Helpers';
import CarGrid from './CarGrid';
import { Col, Form } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import SelectBox from '../../Components/Common/SelectBox/SelectBox';
const style = {
    chips: {
        background: 'green'
    },
    searchBox: {
        border: 'none',
        'border-bottom': '1px solid white',
        'border-radius': '0px'
    },
    multiselectContainer: {
        color: 'green'
    }
};
const options = [
    { name: 'ASTON MARTIN', value: 'ASTON MARTIN' },
    { name: 'AUDI', value: 'AUDI' },
    { name: 'BMW', value: 'BMW' },
    { name: 'CITROEN', value: 'CITROEN' },
    { name: 'FIAT', value: 'FIAT' },
    { name: 'FORD', value: 'FORD' },
    { name: 'HONDA', value: 'HONDA' },
    { name: 'LAND ROVER', value: 'LAND ROVER' },
    { name: 'LEXUS', value: 'LEXUS' },
    { name: 'MAZDA', value: 'MAZDA' },
    { name: 'MERCEDES-BENZ', value: 'MERCEDES-BENZ' },
    { name: 'MINI', value: 'MINI' },
    { name: 'MITSUBISHI', value: 'MITSUBISHI' },
    { name: 'NISSAN', value: 'NISSAN' },
    { name: 'PEUGEOT', value: 'PEUGEOT' },
    { name: 'PORSCHE', value: 'PORSCHE' },
    { name: 'SEAT', value: 'SEAT' },
    { name: 'SKODA', value: 'SKODA' },
    { name: 'SMART', value: 'SMART' },
    { name: 'SUBARU', value: 'SUBARU' },
    { name: 'TOYOTA', value: 'TOYOTA' },
    { name: 'VAUXHALL', value: 'VAUXHALL' },
    { name: 'VOLKSWAGEN', value: 'VOLKSWAGEN' },
    { name: 'VOLVO', value: 'VOLVO' }
];
const CarListing = () => {
    const history = useHistory();
    const [vehicleList, setVehicleList] = useState([]);
    const [total, totalVehicle] = useState(0);
    // const [minprice, minPrice] = useState(0);
    // const [maxprice, maxPrice] = useState(0);
    const [bidcount, bidCount] = useState(0);
    const [popupState, setPopupState] = useState(false);
    const [loader, setLoader] = useState(false);
    const [controlRange, setControlRange] = useState({
        price: { min: 1000, max: 150000 },
        mileage: { min: 0, max: 150000 },
        distance: { min: 0, max: 1000 }
    });
    const [gPrice, setgPrice] = useState({ ...controlRange.price });
    const [mileage, setMileage] = useState({ ...controlRange.mileage });
    const [distance, setDistance] = useState({ ...controlRange.distance });
    const [fuel, setFuel] = useState(null);
    const [selectedMakes, setSelectedMakes] = useState('');
    const state = {
        value: { min: 2, max: 10 }
    };

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        let req = {};
        getAllVehicle(req);
    }, []);

    const getAllVehicle = (req) => {
        setLoader(true);
        listAllVehicle(req)
            .then((res) => {
                if (res.status === false) {
                    console.log(res?.data?.message);
                } else {
                    // setVehicleList(
                    //     res?.data?.data?.data?.map((b) => {
                    //         const details = b?.szVehicleDetails;
                    //         return { ...b, szVehicleDetails: details ? JSON.parse(details) : undefined };
                    //     })
                    // );
                    console.log(selectedMakes, 'selectedMakes');
                    const filteredVehicleList = res?.data?.data?.data
                        ?.map((b) => {
                            const details = b.szVehicleDetails;
                            const parsedDetails = details ? JSON.parse(details) : undefined;
                            if (selectedMakes != '') {
                                if (parsedDetails && selectedMakes == parsedDetails.make) {
                                    return { ...b, szVehicleDetails: parsedDetails };
                                }
                                return null;
                            } else {
                                return { ...b, szVehicleDetails: parsedDetails };
                            }
                        })
                        .filter(Boolean);

                    setVehicleList(filteredVehicleList);
                    totalVehicle(filteredVehicleList.length);

                    // totalVehicle(res?.data?.data?.total);
                    let min = Math.min(...res?.data?.data?.data.map((v) => v.minprice));
                    let max = Math.min(...res?.data?.data?.data.map((v) => v.maxprice));
                    setControlRange((st) => ({ ...st, price: { min: 0, max: max + 100000 } }));
                    setgPrice({ min, max });
                    bidCount(res?.data?.data?.data[0]?.bidcount);
                    console.log(res?.data?.data?.data[0]?.minprice);
                    setLoader(false);
                    setPopupState(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    };
    const fileterList = () => {
        let req = {};
        req['dFromMaximumPrice'] = gPrice.min;
        req['dToMaximumPrice'] = gPrice.max;
        req['szFromMileage'] = mileage.min;
        req['szToMileage'] = mileage.max;
        req['szFromDistance'] = distance.min;
        req['szToDistance'] = distance.max;
        req['szFuelType'] = fuel;
        getAllVehicle(req);
    };
    useEffect(() => {
        if (selectedMakes === '') {
            // setSelectedMakes has finished updating the state
            let req = {};
            setgPrice({ min: 1000, max: 150000 });
            setMileage({ min: 0, max: 150000 });
            setFuel(null);
            getAllVehicle(req);
        }
    }, [selectedMakes]); //to call ResetfileterList

    const ResetfileterList = () => {
        setSelectedMakes('');
    };

    const handlePopup = () => {
        if (popupState) {
            setPopupState(false);
        } else {
            setPopupState(true);
        }
    };

    const onSelect = (e) => {
        setSelectedMakes(e.target.value);
    };

    return (
        <>
            {loader && <LoaderWrapper />}
            <div className="car-listing">
                <CommonBanner>
                    <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
                        <h1 className="car-name">Vehicles For Sale</h1>
                        {/* <div className="detail">
            <span className="info">Monday 7th December</span>
          </div> */}
                    </div>
                </CommonBanner>
                <div className="filter-main">
                    <div className="listing-head">
                        <h3 className="heading">
                            Showing {total > 12 ? 12 : total} vehicles from {total}
                        </h3>
                        <div className="label-button">
                            <div className="filter-caption" onClick={handlePopup}>
                                Filter
                            </div>
                            <div className="spacer"></div>
                            {bidcount > 0 ? (
                                <div className="activebids">
                                    <Link className="btn primary btn-small" to={Path.bidList}>
                                        <span>{bidcount > 1 ? bidcount + ' Active bids' : '1 Active bid'} </span>
                                    </Link>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        {/* <CloseBlack className="icon-close" onClick={handlePopup} />  */}
                    </div>
                    <div className="filterpopup">
                        <Popup
                            onClick={handlePopup}
                            state={popupState}
                            // heading="Filter Vehicles"
                            btnText="Filter"
                        >
                            <form className="form">
                                <div className="filterpopup-inner">
                                    {/* <div className="filter-field">
                <label className="filterlabel">Mileage</label>
              </div>    */}
                                    <div className="row">
                                        <div className="filter-control">
                                            <div className="filter-field">
                                                <label className="filterlabel">Guide Price</label>
                                                <InputRange
                                                    step={1}
                                                    minValue={controlRange.price.min}
                                                    maxValue={controlRange.price.max}
                                                    value={gPrice}
                                                    onChange={(value) => setgPrice(value)}
                                                />
                                                <span>
                                                    £{gPrice.min} – {gPrice.max}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="filter-control">
                                            <div className="filter-field" style={{ marginBottom: 0, paddingBottom: 0 }}>
                                                <label className="filterlabel">Select Make</label>
                                                <select className="input" value={selectedMakes} onChange={onSelect}>
                                                    <option value="">Select Option</option>
                                                    {options.map((m) => {
                                                        return <option value={m.name}>{m.name}</option>;
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="filter-control">
                                            <div className="filter-field">
                                                <label className="filterlabel">Mileage</label>
                                                <InputRange
                                                    step={1}
                                                    minValue={controlRange.mileage.min}
                                                    maxValue={controlRange.mileage.max}
                                                    value={mileage}
                                                    onChange={(value) => setMileage(value)}
                                                />
                                                <span>
                                                    {mileage.min} – {mileage.max}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div className="filter-control">
                  <div className="filter-field">
                    <label className="filterlabel">Distance</label>
                    <InputRange
                      step={1}
                      minValue={controlRange.distance.min}
                      maxValue={controlRange.distance.max}
                      value={distance}
                      onChange={(value) => setDistance(value)}
                    />
                    <span>
                      {distance.min} – {distance.max}
                    </span>
                  </div>
                </div> */}
                                        <div className="filter-control">
                                            <div className="filter-field">
                                                <label className="filterlabel fuel">Fuel</label>
                                                <ul>
                                                    <li>
                                                        <input type="radio" name="szFuel" onClick={() => setFuel(null)} value="0" checked={fuel === null} />{' '}
                                                        <span>All</span>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            name="szFuel"
                                                            onClick={() => setFuel('Petrol')}
                                                            value="Petrol"
                                                            checked={fuel === 'Petrol'}
                                                        />
                                                        <span>Petrol</span>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            name="szFuel"
                                                            onClick={() => setFuel('Diesel')}
                                                            value="Diesel"
                                                            checked={fuel === 'Diesel'}
                                                        />
                                                        <span>Diesel</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ right: 0 }}>
                                        <div className="col-sm-3">
                                            <button className="btn primary btn-small" type="button" onClick={fileterList}>
                                                Apply
                                            </button>
                                        </div>
                                        <div className="col-sm-3">
                                            <button className="btn primary-gray btn-smalls" type="button" onClick={ResetfileterList}>
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Popup>
                    </div>
                </div>
                <div className="listing-wrapper">
                    <div className="card-outer-wrapper">
                        {vehicleList?.map((vehicle, index) => (
                            <div className="card-wrapper">
                                <CarGrid vehicle={vehicle} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarListing;
