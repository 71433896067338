import { createStore } from "redux";

// get token
export function getToken() {
  if (localStorage.getItem("token")) return true;
  else return false;
}

// set token
export function setToken(token) {
  return localStorage.setItem("token", token);
}

// clear token
export function clearToken() {
  return localStorage.clear();
}

// space between car no
export function insertSpace(string) {
  let output = [
    string.slice(0, string.length - 3),
    " ",
    string.slice(string.length - 3),
  ].join("");
  return output;
}

// background scroll
export function setScrollBackground() {
  document.getElementById("my-app").style.overflow = "hidden";
  console.log("set");
}

export function releaseScrollBackground() {
  document.getElementById("my-app").style.overflow = "";
  console.log("resetset");
}

export function updateUsername(username = null) {
  username
    ? localStorage.setItem("szFullName", username)
    : localStorage.getItem("szFullName");
  let fullName = localStorage.getItem("szFullName");
  return fullName;
}

export function todos(state = [], action) {
  switch (action.type) {
    case "ADD_TODO":
      return action.text;
    default:
      return state;
  }
}
export const store = createStore(todos, localStorage.getItem("szName"));
