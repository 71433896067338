import React, { useEffect, useState } from "react";
import { NavLink, Link, Route, Switch, useLocation, useHistory, useParams } from "react-router-dom";

import ChangePassword from "../../Components/Auth/ChangePassword/ChangePassword";
import CommonBanner from "../../Components/Common/CommonBanner/CommonBanner";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Path from "../../Constants/Routes";
import EditProfile from "../EditProfile/EditProfile";
import { getBidList } from "./../../Services/listBids";
import BidList from "./BidList";
import BidsCompleted from "./BidsCompleted";
import BidsLost from "./BidsLost";
import BidsWon from "./BidsWon";
import ViewBids from "./ViewBids";
import CustomerMessage from "./CustomerMessage/CustomerMessage";
import "./CustomerProfile.scss";
import PrivateRoute from "../../PrivateRoute";

const CustomerProfile = () => {
  const [bidsToggle, setBidsToggle] = useState(false);
  const [purchaseToggle, setPurchaseToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState("Active Bids");
  const history = useHistory();
  let { slug } = useParams();
  const [bidList, setBidList] = useState([]);
  useEffect(() => {
    switch(history.location.pathname){
      case Path.bidList:
        setTitle("Active Bids");
        break;
        case Path.bidsWon:
        setTitle("Bids Won");
        break;
        case Path.bidsLost:
        setTitle("Bids Lost");
        break;
        case Path.bidsCompleted:
        setTitle("Bids Completed");
        break;
        case Path.editProfile:
        setTitle("Edit Profile");
        break;
        case Path.changePassword:
        setTitle("Change Password");
        break;
    }
  }, [history.location]);

  // @api
  const getDealerBidList = (req = null) => {
    setLoader(true);
    getBidList()
      .then(async (res) => {
        if (res.status === false) {
          console.log("something went wrong");
        } else {
          await setBidList(res?.data?.data?.data);
          await setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handleToggle = () => {
    setBidsToggle(true);
  };
  return (
    <>
      {loader ? <LoaderWrapper /> : null}
      <div className="customer-profile">
        <CommonBanner>
          <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
            <h1 className="car-name">{title}</h1>
          </div>
        </CommonBanner>
        <div className="container-customer d-flexs">
          <div className="customer-profilemain">
            {/* <div className="filterSection">
              <select className="cg-select" id="AccountDateRangeFilter">
                <option value="ALL">All</option>
                <option value="TODAY">Today</option>
                <option value="YESTERDAY">Yesterday</option>
                <option value="LAST_7_DAYS">Last 7 days</option>
                <option value="LAST_30_DAYS">Last 30 days</option>
              </select>
            </div> */}
            <div className="sideBarSection">
              <div className="sideBarSection-inner">
                <div className="sideBarSection-cg">
                  {/* onClick={() => handleToggle("bid")} */}
                  <nav className="navbar">
                    <NavLink
                       onClick={()=>{                     
                        setTitle("Active Bids");
                      }}
                      exact
                      activeClassName="active"
                      className="navbar__link"
                      to={Path.bidList}
                    >
                      Active Bids
                    </NavLink>
                    {console.log(history)} 
                    <NavLink
                    onClick={()=>{
                     
                      setTitle("Bids Won");
                    }}
                    exact
                      activeClassName={`active ${history.location.pathname == Path.viewBids + slug ? "active" : ''}`}
                      className="navbar__link"
                      to={Path.bidsWon}
                    >
                      Bids Won
                    </NavLink>

                    <NavLink
                     onClick={()=>{
                     
                      setTitle("Bids Lost");
                    }}
                    exact
                      activeClassName="active"
                      className="navbar__link"
                      to={Path.bidsLost} 
                    >
                      Bids Lost
                    </NavLink>
                    <NavLink
                     onClick={()=>{
                     
                      setTitle("Bids Completed");
                    }}
                    exact
                      activeClassName="active"
                      className="navbar__link"
                      to={Path.bidsCompleted} 
                    >
                      Bids Completed
                    </NavLink>
                    <NavLink
                    onClick={()=>{
                     
                      setTitle("Edit Profile");
                    }}
                    exact
                      activeClassName="active"
                      className="navbar__link"
                      to={Path.editProfile} 
                    >
                      Edit Profile
                    </NavLink>
                    <NavLink
                     onClick={()=>{
                     
                      setTitle("Change Password");
                    }}
                    exact
                      activeClassName="active"
                      className="navbar__link"
                      to={Path.changePassword} 
                    >
                      Change Password
                    </NavLink>
                  </nav>
                 
                </div>
                {/* <div className="sideBarSection-cg">
                <h3 className="title">
                    <Link className="item" to={Path.bidList}>
                      <span>Active Bids</span>
                    </Link>
                  </h3>
                   <div
                    className={`sideBarSection-db ${
                      bidsToggle ? "d-block" : ""
                    }`}
                  >
                    <ul>
                      <li>
                        <Link className="item" to={Path}>
                          <span>Won Bids (0)</span>
                        </Link>
                      </li>
                      <li>
                        <Link className="item" to={Path}>
                          <span>Rejected Bids (0)</span>
                        </Link>
                      </li>
                      <li>
                        <Link className="item" to={Path}>
                          <span>Complered (84)</span>
                        </Link>
                      </li>
                    </ul>
                  </div> 
                </div>
                <div className="sideBarSection-cg">
                  <h3 className="title">
                    <Link className="item" to={Path.bidsWon}>
                      <span>Bids Won</span>
                    </Link>
                  </h3>
                </div>
                <div className="sideBarSection-cg">
                  <h3 className="title">
                    <Link className="item" to={Path.bidsLost}>
                      <span>Bids Lost</span>
                    </Link>
                  </h3>
                </div>
                <div className="sideBarSection-cg">
                  <h3 className="title">
                    <Link className="item" to={Path.bidsCompleted}>
                      <span>Bids Completed</span>
                    </Link>
                  </h3>
                </div>
                <div className="sideBarSection-cg">
                  <h3 className="title">
                    <Link className="item" to={Path.editProfile}>
                      <span>Edit Profile</span>
                    </Link>
                  </h3>
                </div>
                <div className="sideBarSection-cg">
                  <h3 className="title">
                    <Link className="item" to={Path.changePassword}>
                      <span>Change Password</span>
                    </Link>
                  </h3>
                </div> */}
              </div>
            </div>
            <div className="vehiclesSection">
              <Switch>
                <PrivateRoute
                  exact={true}
                  path={Path.bidList}
                  component={() => <BidList data={bidList} />}
                />
                <PrivateRoute
                  exact={true}
                  path={Path.bidsWon}
                  component={() => <BidsWon data={bidList} />}
                />
                <PrivateRoute
                  exact={true}
                  path={`${Path.viewBids}:slug`}
                  component={() => <ViewBids data={bidList} />}
                />
                
                <PrivateRoute
                  exact={true}
                  path={Path.bidsLost}
                  component={() => <BidsLost data={bidList} />}
                />
                <PrivateRoute
                  exact={true}
                  path={Path.bidsCompleted}
                  component={() => <BidsCompleted data={bidList} />}
                />

                <PrivateRoute
                  exact={true}
                  path={Path.customerMessage}
                  component={CustomerMessage}
                />
                <PrivateRoute
                  exact={true}
                  path={Path.editProfile}
                  component={EditProfile}
                />
                
                <PrivateRoute
                  exact
                  path={Path.changePassword}
                  component={ChangePassword}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerProfile;
