import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Dealerlogin from "./Components/Auth/DealerLogin/Dealerlogin";
import DealerSignup from "./Components/Auth/DealerSignup/DealerSignup";
import Path from "./Constants/Routes";
import { getToken } from "./Helpers/Helpers";
import Layout from "./Layout";
import process from "./Pages/DealerProcess/DealerProcess";
import pricing from "./Pages/Pricing/Pricing";
import forgot from "./Components/Auth/ForgotPassword/ForgotPassword";
import ContactUs from "./Pages/ContactUs/ContactUs";
import CookiePolicy from "./Pages/CookiePolicy/CookiePolicy";
import CustomerReview from "./Pages/CustomerReview/CustomerReview";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions/TermsConditions";
import Routes from "./Routes";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? <Component {...props} /> : <Redirect to={Path.login} />
      }
    />
  );
};

const App = () => {
  const location = useLocation();
  return (
    <Layout>
      <Switch location={location}>
        <Route exact path={Path.login} component={Dealerlogin} />
        <Route exact path={Path.contactUs} component={ContactUs} />
        <Route exact path={Path.dealerProcess} component={process} />
        <Route exact path={Path.forgotPassword} component={forgot} />
        <Route exact path={Path.customerReview} component={CustomerReview} />
        <Route exact path={Path.cookiePolicy} component={CookiePolicy} />
        <Route exact path={Path.privacyPolicy} component={PrivacyPolicy} />
        <Route exact path={Path.pricing} component={pricing} />
        <Route exact path={Path.termsConditions} component={TermsConditions} />
        <Route exact path={Path.signup} component={DealerSignup} />
        <PrivateRoute exact component={Routes} />
      </Switch>
    </Layout>
  );
};

export default App;
