import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Path from "../../../Constants/Routes";
import { forgetPassword } from "../../../Services/forgetPassword";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import LoaderWrapper from "../../Common/Loader/Loader";
import { validate } from "../DealerLogin/validate";
import "./../../Auth/Auth.scss";



  let ForgotPassword = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    const [message, setMessage] = useState("");
    const [field, setField] = useState({
      szEmail: "",
      szPassword: "",
    });
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    const notify = (message) =>
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    // @api call
    const handleForget = (values) => {
      
      if (validate(values)) {
        console.log("=========forget",values)
        setLoader(true);
        forgetPassword(values)
          .then((res) => {
            if (res.success === false) {
              notify(res?.data?.data);
            } else {
              // const token = res?.data?.data?.token;
              // localStorage.setItem("token", token);
              // localStorage.setItem("szName", res?.data?.data?.szFullName);
              props.dispatch(reset("dealerForget"));
              setLoader(false);
              toast.success("Password send successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setTimeout(() => {
                history.push(Path.login);
              }, 3000);
              
            }
          })
          .catch((err) => {
            setMessage(err.message);
            notify(err.data);
            setLoader(false);
          });
      }
    };
  return (

    <>
    {loader && <LoaderWrapper />}
    <div className="auth-container">
      <form
        onSubmit={handleSubmit(handleForget)}
        className="auth-form-wrapper logincls"
      >
        <h1 className="heading">Sign in to your account</h1>
        <div className="form-field">
          <label>Email address *</label>
          <Field
            name="szEmail"
            placeholder="e.g. johndeo@gmail.com"
            type="email"
            component={Input}
            className={`width-100`}
          />
        </div>
        <Button className="primary width-100" type="submit">
          Submit
        </Button>
      </form>
      <ToastContainer autoClose={3000} />
    </div>
  </>
    
    );
  }

  ForgotPassword = reduxForm({
    // a unique name for the form
    form: "dealerForget",
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate,
  })(ForgotPassword);
  
  export default connect((state) => {
    const formValues = state?.form["dealerLogin"]?.values;
    return {
      formValues,
      state,
    };
  })(ForgotPassword);
  // export default ForgotPassword;


