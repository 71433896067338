export const validate = (values) => {
  const errors = {};
  if (!values.szEmail) {
    errors.szEmail = "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.szEmail)
  ) {
    errors.szEmail = "Invalid email address";
  }
  if (!values.szPhoneNumber) {
    errors.szPhoneNumber = "Required";
  } else if (
    !/((\+44\s?\(0\)\s?\d{2,4})|(\+44\s?(01|02|03|07|08)\d{2,3})|(\+44\s?(1|2|3|7|8)\d{2,3})|(\(\+44\)\s?\d{3,4})|(\(\d{5}\))|((01|02|03|07|08)\d{2,3})|(\d{5}))(\s|-|.)(((\d{3,4})(\s|-)(\d{3,4}))|((\d{6,7})))/.test(
      values.szPhoneNumber
    )
  ) {
    errors.szPhoneNumber = "Invalid phone number";
  }
  return errors;
};
